import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  allFabricIssueList: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
  singleFabricIssueList: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
};

export const fabricIssueSlice = createSlice({
  name: 'fabricIssue',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setAllFabricIssueListing: (state, action) => {
      state.allFabricIssueList.items = action.payload;
      state.allFabricIssueList.error = {
        isError: false,
        message: '',
      };
    },
    setAllFabricIssueListingLoading: (state, action) => {
      state.allFabricIssueList.loading = action.payload;
    },
    setAllFabricIssueListingError: (state, action) => {
      state.allFabricIssueList.items = {};
      state.allFabricIssueList.error = action.payload;
    },
    setSingleFabricIssueListing: (state, action) => {
      state.singleFabricIssueList.items = action.payload;
      state.singleFabricIssueList.error = {
        isError: false,
        message: '',
      };
    },
    setSingleFabricIssueListingLoading: (state, action) => {
      state.singleFabricIssueList.loading = action.payload;
    },
    setSingleFabricIssueListingError: (state, action) => {
      state.singleFabricIssueList.items = {};
      state.singleFabricIssueList.error = action.payload;
    },
  },
});

export default fabricIssueSlice.reducer;

// Action creators are generated for each case reducer function
export const {
  setLoading,
  setAllFabricIssueListing,
  setAllFabricIssueListingLoading,
  setAllFabricIssueListingError,
  setSingleFabricIssueListing,
  setSingleFabricIssueListingLoading,
  setSingleFabricIssueListingError,
} = fabricIssueSlice.actions;

export const fabricIssueItemsSelector = (state) => state.fabricIssue;
