import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  billerPartyList: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
  singlePartyList: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
  receiverPartyList: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
};

export const partySlice = createSlice({
  name: 'party',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setAllBillerPartyListing: (state, action) => {
      state.billerPartyList.items = action.payload;
      state.billerPartyList.error = {
        isError: false,
        message: '',
      };
    },
    setAllBillerPartyListingLoading: (state, action) => {
      state.billerPartyList.loading = action.payload;
    },
    setAllBillerPartyListingError: (state, action) => {
      state.billerPartyList.items = {};
      state.billerPartyList.error = action.payload;
    },
    setAllReceiverPartyListing: (state, action) => {
      state.receiverPartyList.items = action.payload;
      state.receiverPartyList.error = {
        isError: false,
        message: '',
      };
    },
    setAllReceiverPartyListingLoading: (state, action) => {
      state.receiverPartyList.loading = action.payload;
    },
    setAllReceiverPartyListingError: (state, action) => {
      state.receiverPartyList.items = {};
      state.receiverPartyList.error = action.payload;
    },
    setSinglePartyListing: (state, action) => {
      state.singlePartyList.items = action.payload;
      state.singlePartyList.error = {
        isError: false,
        message: '',
      };
    },
    setSinglePartyListingLoading: (state, action) => {
      state.singlePartyList.loading = action.payload;
    },
    setSinglePartyListingError: (state, action) => {
      state.singlePartyList.items = {};
      state.singlePartyList.error = action.payload;
    },
  },
});

export default partySlice.reducer;

// Action creators are generated for each case reducer function
export const {
  setLoading,
  setAllBillerPartyListing,
  setAllBillerPartyListingLoading,
  setAllBillerPartyListingError,
  setAllReceiverPartyListing,
  setAllReceiverPartyListingLoading,
  setAllReceiverPartyListingError,
  setSinglePartyListing,
  setSinglePartyListingLoading,
  setSinglePartyListingError,
} = partySlice.actions;

export const partyItemsSelector = (state) => state.party;
