import { Box, Grid, Typography } from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import CustomBox from '../CommonComp/CustomBox/CustomBox';
import { DATA_LIMIT } from '../../data/constants';
import * as Yup from 'yup';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import CustomCheckbox from '../CommonComp/CustomInput/CheckboxField';
import Divder from '../CommonComp/Divder';
import Loader from '../CommonComp/CustomLoading/Loader';
import { useNavigate } from 'react-router-dom';
import MatTable from '../Tables/MatTable';
import { DefaultDesignSizes } from '../../data/designPartyData';
import {
  createDesignDetail,
  getSingleDesignListing,
  updateDesignDetail,
} from '../../Services/designApis';
import { designItemsSelector } from '../../redux/design';
import ConfirmationModal from '../Modals/ConfirmationModal';
import AddFabricModal from '../Modals/Design/AddFabricModal';

const limit = DATA_LIMIT;

function DesignListDetailPage({ designId, isEdit }) {
  const { singleDesignList } = useSelector(designItemsSelector);
  const [openAddEditDialog, setOpenAddEditDialog] = useState(false);
  const [isLoadingState, setIsLoadingState] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [selectedCategoryData, setSelectedCategoryData] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [mainDataOfProduct, setMainDataOfProfuct] = useState({});
  const handleConfirm = async (values, { resetForm }) => {
    delete values?.sureChangesConsent;
    // delete values?.balanceQuantityFabric;
    setIsLoadingState(true);
    if (isEdit) {
      let resp = await updateDesignDetail(values, designId);
      if (resp) {
        getPartyDetails();
      }
      setIsLoadingState(false);
    } else {
      let resp = await createDesignDetail(values);
      if (resp) {
        resetForm({
          sku: '',
          name: '',
          isActive: Boolean(true),
          size: [
            {
              name: 'S',
            },
            {
              name: 'M',
            },
            {
              name: 'L',
            },
            {
              name: 'XL',
            },
            {
              name: 'XXL',
            },
          ],
        });
      }
      setIsLoadingState(false);
      navigate('/design');
    }
  };
  const handleAdd = (value) => {
    setOpenAddEditDialog(value);
    setSelectedCategoryData(mainDataOfProduct);
  };
  const getPartyDetails = async () => {
    const payload = {
      page: String(1),
      limit: limit,
      designId: isEdit ? designId : '',
    };
    if (isEdit) {
      dispatch(getSingleDesignListing(payload));
    }
  };
  useEffect(() => {
    if (isEdit) {
      getPartyDetails();
    }
  }, []);
  // const getListing = async () => {
  //   getPartyDetails();
  // };
  const handleDelete = async (value) => {
    // try {
    //   if (value && deleteData) {
    //     const resp = await deleteCategory(deleteData);
    //     if (resp) {
    //       getListing();
    //     }
    //   } else {
    //     setDeleteData(null);
    //   }
    // } catch (e) {}
  };
  const handleCofirmModalOpen = (value) => {
    setConfirmDelete(value);
  };
  useEffect(() => {
    if (
      singleDesignList?.items &&
      Object.keys(singleDesignList?.items)?.length > 0
    ) {
      setMainDataOfProfuct(singleDesignList?.items);
      // setMainDataOfProfuct({});
    }
  }, [singleDesignList?.items]);
  const formik = useFormik({
    initialValues: {
      sku: isEdit ? mainDataOfProduct?.sku || '' : '',
      // name: isEdit ? mainDataOfProduct?.name || '' : '',
      // totalQuantityFabric: isEdit
      //   ? mainDataOfProduct?.totalQuantityFabric || 0
      //   : 0,
      // balanceQuantityFabric: isEdit
      //   ? mainDataOfProduct?.balanceQuantityFabric || 0
      //   : 0,
      isActive: isEdit
        ? Boolean(mainDataOfProduct?.isActive) || Boolean(true)
        : Boolean(true),
      sureChangesConsent: Boolean(false),
      size: isEdit
        ? mainDataOfProduct?.size || [
            {
              name: 'S',
            },
            {
              name: 'M',
            },
            {
              name: 'L',
            },
            {
              name: 'XL',
            },
            {
              name: 'XXL',
            },
          ]
        : [
            {
              name: 'S',
            },
            {
              name: 'M',
            },
            {
              name: 'L',
            },
            {
              name: 'XL',
            },
            {
              name: 'XXL',
            },
          ],
    },
    validationSchema: Yup.object({
      sku: Yup.string().required('Design SKU is required.'),
      // name: Yup.string().required('Design Name is required.'),
      // totalQuantityFabric: Yup.number()
      //   .required('Total Fabric Meter is required.')
      //   .min(0, 'It Must Be 0 or Greater Than 0'),
      isActive: Yup.string().required('Is Active is required.'),
      isActive: Yup.string().required('Is Active is required.'),
      size: Yup.array().of(
        Yup.object().shape({
          name: Yup.string().required('Name is required'),
        }),
      ),
    }),
    enableReinitialize: true,
    onSubmit: handleConfirm,
  });
  const columnForSize = [
    {
      label: 'Name',
      id: 'name',
      format: (columnData, rowIndex, formatCallback, arr, errors, row) => (
        <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
          <CustomInput
            InputLabelProps={{ shrink: true }}
            select
            SelectProps={{
              native: true,
            }}
            fullWidth={true}
            variant="outlined"
            sx={{ minWidth: 300 }}
            label="Select Size"
            placeholder="Select Size"
            InputProps={{
              classes: {
                notchedOutline: 'rounded',
              },
            }}
            id={`size[${rowIndex}].name`}
            name={`size[${rowIndex}].name`}
            value={columnData}
            onChange={formik.handleChange}
            error={
              formik.touched.size &&
              formik.touched.size[rowIndex]?.name &&
              formik.errors.size &&
              formik.errors.size[rowIndex]?.name
                ? true
                : false
            }
            helperText={
              formik.touched.size &&
              formik.touched.size[rowIndex]?.name &&
              formik.errors.size &&
              formik.errors.size[rowIndex]?.name
            }
          >
            <option key="" value="">
              Select Quantity
            </option>
            {DefaultDesignSizes?.map((data, index) => {
              return (
                <option key={data + index} value={data}>
                  {data}
                </option>
              );
            })}
          </CustomInput>
        </span>
      ),
    },
  ];
  const actionBodyForSizes = (data, rowIndex) => {
    return (
      <>
        <div style={{ display: 'flex' }}>
          <CustomButton
            onClick={() => handleRemoveNewSize(data, rowIndex)}
            sx={{
              padding: '5px 10px',
              marginRight: 1,
              fontSize: '12px',
              bgcolor: 'buttonDanger.main',
              color: 'buttonSecondary.contrastText',
            }}
          >
            Delete
          </CustomButton>
        </div>
      </>
    );
  };
  const handleAddNewSize = (value) => {
    let tempArray = [...formik.values?.size];
    let emptyObj = {
      name: '',
    };
    tempArray?.push(emptyObj);
    formik.setValues({ ...formik.values, size: tempArray });
  };

  const handleOpenAddSize = (value) => {
    let tempArray = [...formik.values?.size];
    let emptyObj = {
      name: '',
    };
    tempArray?.push(emptyObj);
    formik.setValues({ ...formik.values, size: tempArray });
  };

  const handleRemoveNewSize = (data, rowIndex) => {
    let tempArray = [...formik?.values?.size];
    tempArray.splice(rowIndex, 1);
    formik.setValues({ ...formik?.values, size: tempArray });
  };
  return (
    <>
      <div>
        {(singleDesignList?.loading || isLoadingState) && <Loader />}
        <form onSubmit={formik.handleSubmit}>
          <CustomBox padding={3}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CustomInput
                  label="Design SKU"
                  fullWidth
                  placeholder="Enter Design SKU"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  onChange={formik.handleChange}
                  name="sku"
                  value={formik.values.sku}
                  error={formik.touched.sku && Boolean(formik.errors.sku)}
                  helperText={formik.touched.sku && formik.errors.sku}
                />
              </Grid>
              {/* <Grid item xs={12} sm={6}>
                <CustomInput
                  label="Design Name"
                  fullWidth
                  placeholder="Enter Design Name"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  onChange={formik.handleChange}
                  name="name"
                  value={formik.values.name}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </Grid> */}
              <Grid item xs={12}>
                <CustomCheckbox
                  label={'Is Active'}
                  labelPlacement={'end'}
                  subLableText={'Checked'}
                  handleChange={(e) => {
                    formik.setFieldValue(
                      'isActive',
                      e.target.checked == true ? true : false,
                    );
                  }}
                  name="isActive"
                  value={Boolean(formik.values.isActive)}
                  error={
                    formik.touched.isActive && Boolean(formik.errors.isActive)
                  }
                  helperText={formik.touched.isActive && formik.errors.isActive}
                />
              </Grid>
            </Grid>
          </CustomBox>
          <Divder spacing={1} />
          {/* <Divder spacing={1} />
          <CustomBox padding={3}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                px: (theme) => theme.spacing(3),
                py: (theme) => theme.spacing(2),
              }}
            >
              <Typography variant="h3" sx={{}}>
                Fabric Details
              </Typography>
              {isEdit && (
                <CustomButton
                  className="buttonDense"
                  onClick={() => {
                    handleAdd(true);
                  }}
                  color={'buttonPrimary'}
                >
                  Add New Fabric
                </CustomButton>
              )}
            </Box>
            <Divder spacing={1} />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CustomCheckbox
                  label={
                    'Are You Sure Want To Make Changes For Total Fabric Quantity??'
                  }
                  labelPlacement={'end'}
                  subLableText={'Yes'}
                  handleChange={(e) => {
                    formik.setFieldValue(
                      'sureChangesConsent',
                      e.target.checked == true ? true : false,
                    );
                  }}
                  name="sureChangesConsent"
                  value={Boolean(formik.values.sureChangesConsent)}
                  error={
                    formik.touched.sureChangesConsent &&
                    Boolean(formik.errors.sureChangesConsent)
                  }
                  helperText={
                    formik.touched.sureChangesConsent &&
                    formik.errors.sureChangesConsent
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomInput
                  label="Total Available Fabric"
                  fullWidth
                  placeholder="Enter Total Available Fabric This Field With Care"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  onChange={formik.handleChange}
                  disabled={!formik.values.sureChangesConsent}
                  name="totalQuantityFabric"
                  value={formik.values.totalQuantityFabric}
                  error={
                    formik.touched.totalQuantityFabric &&
                    Boolean(formik.errors.totalQuantityFabric)
                  }
                  helperText={
                    formik.touched.totalQuantityFabric &&
                    formik.errors.totalQuantityFabric
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomInput
                  label="Total Balance Fabric"
                  fullWidth
                  placeholder="Enter Total Balance Fabric"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  disabled={true}
                  onChange={formik.handleChange}
                  name="balanceQuantityFabric"
                  value={formik.values.balanceQuantityFabric}
                  error={
                    formik.touched.balanceQuantityFabric &&
                    Boolean(formik.errors.balanceQuantityFabric)
                  }
                  helperText={
                    formik.touched.balanceQuantityFabric &&
                    formik.errors.balanceQuantityFabric
                  }
                />
              </Grid>
            </Grid>
          </CustomBox> */}
          <Divder spacing={1} />
          <Divder spacing={1} />
          <CustomBox padding={0}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                px: (theme) => theme.spacing(3),
                py: (theme) => theme.spacing(2),
              }}
            >
              <Typography variant="h3" sx={{}}>
                Sizes
              </Typography>
              <CustomButton
                className="buttonDense"
                onClick={() => handleAddNewSize(formik.values)}
                color={'buttonPrimary'}
              >
                Add New Size
              </CustomButton>
            </Box>
            <Divder spacing={1} />
            <MatTable
              showCheckbox={false}
              columns={columnForSize}
              data={formik.values.size}
              action={actionBodyForSizes}
              total={0}
              handlePageChange={(newPage) => {
                getListing(
                  {
                    page: String(newPage),
                  },
                  setPage,
                );
              }}
              pagination={false}
            />
          </CustomBox>
          <Divder spacing={1} />
          <CustomButton
            sx={{ marginTop: 2 }}
            type="submit"
            color={'buttonPrimary'}
          >
            Submit
          </CustomButton>
        </form>
      </div>
      {confirmDelete && (
        <ConfirmationModal
          open={confirmDelete}
          handleConfirm={handleDelete}
          setOpen={handleCofirmModalOpen}
        />
      )}
      {openAddEditDialog && (
        <AddFabricModal
          open={openAddEditDialog}
          handleOpen={handleAdd}
          getListing={getPartyDetails}
          selectedData={selectedCategoryData}
        />
      )}
    </>
  );
}

export default DesignListDetailPage;
