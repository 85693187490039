export const ChalanList = {
  tabs: [
    {
      label: 'All Chalan List',
      id: 1,
    },
    {
      label: 'Top Chalan List',
      id: 2,
    },
  ],
};
