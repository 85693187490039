import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Divder from '../../Components/CommonComp/Divder';
import CustomBox from '../../Components/CommonComp/CustomBox/CustomBox';
import { BillerPartyData } from '../../data/billerPartyData';
import CustomTabs from '../../Components/CommonComp/CustomTabs/CustomTabs';
import BillerPartyTabBody from '../../Components/BillerParty/BillerPartyTabBody';

function BillerParty({ handleNavParams, addUserModal, handleAddUserModal }) {
  const [tabValue, setTabValue] = useState(1);

  const handleTabs = (data) => {
    setTabValue(data.id);
    handleNavParams({
      navTitle: data.label,
    });
  };

  return (
    <Box
      component="main"
      sx={(theme) => {
        return {
          flexGrow: 1,
          // bgcolor: theme.backgroundColor.box.main,
          // p: 4,
          // pl: 0,
          // pl: 0,
        };
      }}
    >
      <CustomBox padding={0}>
        <CustomTabs tabList={BillerPartyData.tabs} tabClick={handleTabs} />
      </CustomBox>
      <Divder spacing={1} />
      <BillerPartyTabBody activeTab={tabValue} />
      <Divder spacing={1.2} />
    </Box>
  );
}

export default BillerParty;
