import React, { useState } from 'react';
import MainLayout from '../Layouts/MainLayout';
import BillingInvoiceDetails from './BillingInvoiceDetails';

function BillingInvoice({ isEdit }) {
  return (
    <MainLayout
      navTitle={'Biller Invoice'}
      navSubTitle={'Welcome to Classy Vouge'}
    >
      <BillingInvoiceDetails isEdit={isEdit} />
    </MainLayout>
  );
}

export default BillingInvoice;
