import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import LoginLayout from '../Screen/Layouts/LoginLayout';
import { ProtectedRoute } from './ProtectedRoute';
import DashboardContainer from '../Screen/Dashboard/index';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PageNotFound from '../Screen/NotFound/PageNotFound';
import { MaintenanceRoute } from './MaintenanceRoute';
import MaintenanceLayout from '../Screen/Layouts/MaintenanceLayout';
import BillerPartyContainer from '../Screen/BillerParty';
import ReceiverPartyContainer from '../Screen/ReceiverParty';
import BillerPartyDetailsContainer from '../Screen/BillerParty/BillerPartyDetailsContainer';
import ReceiverPartyDetailsContainer from '../Screen/ReceiverParty/ReceiverPartyDetailsContainer';
import DesignListContainer from '../Screen/DesignList';
import DesignListDetailsContainer from '../Screen/DesignList/DesignListDetailsContainer';
import ChalanDetailsContainer from '../Screen/Chalan/ChalanDetailsContainer';
import ChalanContainer from '../Screen/Chalan';
import BillingInvoice from '../Screen/Chalan/BillingInvoice';
import JobworkContainer from '../Screen/Jobwork';
import JobWorkDetailsContainer from '../Screen/Jobwork/ChalanDetailsContainer';
import FabricIssueContainer from '../Screen/FabricIssue';
import FabricIssueDetailsContainer from '../Screen/FabricIssue/FabricIssueDetailsContainer';
import FabricIssueInvoice from '../Screen/FabricIssue/FabricIssueInvoice';
import JobWorkInvoice from '../Screen/Jobwork/JobWorkInvoice';
const AllRoutes = ({ user, isMaintenance }) => {
  return (
    <>
      <ToastContainer />
      <Routes>
        <Route element={<MaintenanceRoute isMaintenance={isMaintenance} />}>
          {/* ============= LOGEDOUT ROUTES ========== */}
          <Route index element={<LoginLayout />} />
          <Route path="login" element={<LoginLayout />} />

          {/* ============= LOGEDIN ROUTES ========== */}

          <Route element={<ProtectedRoute isAllowed={!!user} />}>
            <Route path="dashboard" element={<DashboardContainer />} />

            <Route path="biller">
              <Route index={true} element={<BillerPartyContainer />} />
              <Route path="billerDetail">
                <Route
                  index={true}
                  element={<Navigate to={'/biller'} replace={true} />}
                />
                <Route
                  path=":partyId"
                  element={<BillerPartyDetailsContainer isEdit={true} />}
                />
              </Route>
              <Route
                path="addBiller"
                element={<BillerPartyDetailsContainer isEdit={false} />}
              />
            </Route>
            <Route path="receiver">
              <Route index={true} element={<ReceiverPartyContainer />} />
              <Route path="receiverDetail">
                <Route
                  index={true}
                  element={<Navigate to={'/receiver'} replace={true} />}
                />
                <Route
                  path=":partyId"
                  element={<ReceiverPartyDetailsContainer isEdit={true} />}
                />
              </Route>
              <Route
                path="addReceiver"
                element={<ReceiverPartyDetailsContainer isEdit={false} />}
              />
            </Route>
            <Route path="design">
              <Route index={true} element={<DesignListContainer />} />
              <Route path="designDetail">
                <Route
                  index={true}
                  element={<Navigate to={'/design'} replace={true} />}
                />
                <Route
                  path=":designId"
                  element={<DesignListDetailsContainer isEdit={true} />}
                />
              </Route>
              <Route
                path="addDesign"
                element={<DesignListDetailsContainer isEdit={false} />}
              />
            </Route>
            <Route path="chalan">
              <Route index={true} element={<ChalanContainer />} />
              <Route path="chalanDetail">
                <Route
                  index={true}
                  element={<Navigate to={'/chalan'} replace={true} />}
                />
                <Route
                  path=":billingId"
                  element={<ChalanDetailsContainer isEdit={true} />}
                />
              </Route>
              <Route
                path="addChalan"
                element={<ChalanDetailsContainer isEdit={false} />}
              />
              <Route path="invoice">
                <Route
                  index={true}
                  element={<Navigate to={'/chalan'} replace={true} />}
                />
                <Route path=":billingId" element={<BillingInvoice />} />
              </Route>
            </Route>
            <Route path="fabricIssue">
              <Route index={true} element={<FabricIssueContainer />} />
              <Route path="fabricIssueDetail">
                <Route
                  index={true}
                  element={<Navigate to={'/fabricIssue'} replace={true} />}
                />
                <Route
                  path=":fabricIssueId"
                  element={<FabricIssueDetailsContainer isEdit={true} />}
                />
              </Route>
              <Route
                path="issueFabric"
                element={<FabricIssueDetailsContainer isEdit={false} />}
              />
              <Route path="invoice">
                <Route
                  index={true}
                  element={<Navigate to={'/fabricIssue'} replace={true} />}
                />
                <Route path=":fabricIssueId" element={<FabricIssueInvoice />} />
              </Route>
            </Route>
            <Route path="jobwork">
              <Route index={true} element={<JobworkContainer />} />
              <Route path="jobworkDetail">
                <Route
                  index={true}
                  element={<Navigate to={'/jobwork'} replace={true} />}
                />
                <Route
                  path=":jobWorkId"
                  element={<JobWorkDetailsContainer isEdit={true} />}
                />
              </Route>
              <Route
                path="addJobwork"
                element={<JobWorkDetailsContainer isEdit={false} />}
              />
              <Route path="invoice">
                <Route
                  index={true}
                  element={<Navigate to={'/jobwork'} replace={true} />}
                />
                <Route path=":jobWorkId" element={<JobWorkInvoice />} />
              </Route>
            </Route>
          </Route>

          {/* ============= COMMON ROUTES ========== */}
        </Route>
        <Route path="*" element={<PageNotFound />} />
        <Route path="maintenance" element={<MaintenanceLayout />} />
      </Routes>
    </>
  );
};

export default AllRoutes;
