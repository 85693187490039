import axios from 'axios';
import { toast } from 'react-toastify';
import ApiConfig from '../config/ApiConfig';
import { errorMessage } from '../lib/helper';
import { STATUS_200, STATUS_300 } from '../data/constants';
import {
  setAllFabricIssueListing,
  setAllFabricIssueListingLoading,
  setAllFabricIssueListingError,
  setSingleFabricIssueListing,
  setSingleFabricIssueListingLoading,
  setSingleFabricIssueListingError,
} from '../redux/fabricIssue';

export const getAllFabricIssueListing = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setAllFabricIssueListingLoading(true));
        const resp = await axios.get(`${ApiConfig.adminFabricIssueLists}`, {
          params: payload,
        });
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setAllFabricIssueListing(resp.data.data));
          dispatch(setAllFabricIssueListingLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setAllFabricIssueListingError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setAllFabricIssueListingLoading(false));
      }
    };
  }
};

export const getSingleFabricIssueListing = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setSingleFabricIssueListingLoading(true));
        const resp = await axios.get(`${ApiConfig.adminFabricIssueLists}`, {
          params: payload,
        });
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setSingleFabricIssueListing(resp.data.data));
          dispatch(setSingleFabricIssueListingLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setSingleFabricIssueListingError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setSingleFabricIssueListingLoading(false));
      }
    };
  }
};

export const createFabricIssueDetail = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(
        `${ApiConfig.adminCreateFabricIssue}`,
        payload,
      );
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp.data.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};

export const updateFabricIssueDetail = async (payload, fabricIssueId) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.put(
        `${ApiConfig.adminUpdateFabricIssue}/${fabricIssueId}`,
        payload,
      );
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp?.data?.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
    }
  }
};

export const deleteFabricIssueDetail = async (fabricIssueId) => {
  if (fabricIssueId !== undefined) {
    try {
      const resp = await axios.delete(
        `${ApiConfig.adminDeleteFabricIssue}/${fabricIssueId}`,
      );
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp?.data?.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};
