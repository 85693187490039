import React, { useState } from 'react';
import MainLayout from '../Layouts/MainLayout';
import FabricIssueInvoiceDetails from './FabricIssueInvoiceDetails';

function FabricIssueInvoice({ isEdit }) {
  return (
    <MainLayout
      navTitle={'Fabric Issue Invoice'}
      navSubTitle={'Welcome to Classy Vouge'}
    >
      <FabricIssueInvoiceDetails isEdit={isEdit} />
    </MainLayout>
  );
}

export default FabricIssueInvoice;
