import axios from 'axios';
import { toast } from 'react-toastify';
import ApiConfig from '../config/ApiConfig';
import { errorMessage } from '../lib/helper';
import { STATUS_200, STATUS_300 } from '../data/constants';
import {
  setAllDesignListing,
  setAllDesignListingLoading,
  setAllDesignListingError,
  setSingleDesignListing,
  setSingleDesignListingLoading,
  setSingleDesignListingError,
} from '../redux/design';

export const getAllDesignListing = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setAllDesignListingLoading(true));
        const resp = await axios.get(`${ApiConfig.adminDesignLists}`, {
          params: payload,
        });
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setAllDesignListing(resp.data.data));
          dispatch(setAllDesignListingLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setAllDesignListingError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setAllDesignListingLoading(false));
      }
    };
  }
};

export const getSingleDesignListing = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setSingleDesignListingLoading(true));
        const resp = await axios.get(`${ApiConfig.adminDesignLists}`, {
          params: payload,
        });
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setSingleDesignListing(resp.data.data));
          dispatch(setSingleDesignListingLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setSingleDesignListingError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setSingleDesignListingLoading(false));
      }
    };
  }
};

export const getDesignBySearch = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.get(`${ApiConfig.adminDesignLists}`, {
        params: payload,
      });
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        return resp?.data;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};
export const createDesignDetail = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.adminCreateDesign}`, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp.data.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};

export const addFabricToDesign = async (payload, designId) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.put(
        `${ApiConfig.adminAddFabricToDesign}/${designId}`,
        payload,
      );
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp?.data?.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
    }
  }
};

export const updateDesignDetail = async (payload, designId) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.put(
        `${ApiConfig.adminUpdateDesign}/${designId}`,
        payload,
      );
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp?.data?.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
    }
  }
};

export const deleteDesignDetail = async (designId) => {
  if (designId !== undefined) {
    try {
      const resp = await axios.delete(
        `${ApiConfig.adminDeleteDesign}/${designId}`,
      );
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp?.data?.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};
