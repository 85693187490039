import React from 'react';
import CustomBox from '../CommonComp/CustomBox/CustomBox';
import AllReceiverParty from './AllReceiverParty';

function ReceiverPartyTabBody({ activeTab }) {
  return (
    <CustomBox
      padding={0}
      sx={{
        minHeight: 200,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {activeTab == 1 && <AllReceiverParty />}
    </CustomBox>
  );
}

export default ReceiverPartyTabBody;
