import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
} from '@mui/material';
import CustomInput from '../../CommonComp/CustomInput/CustomInput';
import CustomButton from '../../CommonComp/Buttons/CustomButton';
import { addFabricToDesign } from '../../../Services/designApis';
import Loader from '../../CommonComp/CustomLoading/Loader';

function AddFabricModal({ open, handleOpen, getListing, selectedData }) {
  const [loading, setLoading] = useState(false);

  const handleConfirm = async (values, { resetForm }) => {
    setLoading(true);
    let resp = await addFabricToDesign(values, selectedData?._id);
    if (resp) {
      resetForm({
        quantityFabric: 0,
      });
      getListing();
      handleOpen(false);
    }
    setLoading(false);
  };
  const formik = useFormik({
    initialValues: {
      quantityFabric: 0,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      quantityFabric: Yup.number().required('Fabric Meter is required.'),
    }),
    onSubmit: handleConfirm,
  });

  return (
    <div>
      {loading && <Loader />}
      <Dialog maxWidth={'xs'} fullWidth open={open}>
        <DialogTitle variant="h3">{'Add Fabric Meters'}</DialogTitle>
        <DialogContent>
          <Box
            noValidate
            component="form"
            // sx={{
            //   width: 400
            // }}
            onSubmit={formik.handleSubmit}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CustomInput
                  label="Add Fabric In Meters"
                  fullWidth
                  placeholder="Add Fabric In Meters"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  onChange={formik.handleChange}
                  name="quantityFabric"
                  value={formik.values.quantityFabric}
                  error={
                    formik.touched.quantityFabric &&
                    Boolean(formik.errors.quantityFabric)
                  }
                  helperText={
                    formik.touched.quantityFabric &&
                    formik.errors.quantityFabric
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </Grid>
            <div
              style={{ display: 'flex', justifyContent: 'end', marginTop: 15 }}
            >
              <CustomButton
                color={'buttonPrimary'}
                onClick={formik.handleSubmit}
                loading={false}
              >
                Confirm
              </CustomButton>
              <CustomButton
                onClick={() => {
                  formik.resetForm({
                    email: '',
                    status: '',
                    nextFollowUpsDate: '',
                    heading: '',
                    description: '',
                    createdBy: '',
                  });
                  handleOpen(false);
                }}
                disabled={loading}
              >
                Cancel
              </CustomButton>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default AddFabricModal;
