import { createTheme } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
export let theme = createTheme();
theme = createTheme(theme, {
  status: {
    danger: orange[500],
  },
  palette: {
    primary: {
      main: '#000',
      contrastText: '#464255',
    },
    buttonFirst: {
      main: '#28A745',
      contrastText: '#FFFFFF',
    },
    buttonSecond: {
      main: '#DC3545',
      contrastText: '#FFFFFF',
    },
    buttonThird: {
      main: '#17A2B8',
      contrastText: '#FFFFFF',
    },
    buttonFourth: {
      main: '#6C757D',
      contrastText: '#FFFFFF',
    },
    buttonFive: {
      main: '#FFC107',
      contrastText: '#000000',
    },
    buttonSix: {
      main: '#745BE7',
      contrastText: '#FFFFFF',
    },
    secondaryText: {
      main: '#fff0',
      contrastText: '#A3A3A3',
    },
    buttonNormal: {
      main: '#F3F2F7',
      contrastText: '#464255',
      ':disabled': {
        backgroundColor: '#ff0',
      },
    },
    buttonPrimary: {
      main: '#58CDFF',
      contrastText: '#FFF',
      ':disabled': {
        backgroundColor: '#ff0',
      },
    },
    buttonPrimaryDense: {
      main: 'rgb(229, 245, 255)',
      contrastText: 'black',
      ':disabled': {
        backgroundColor: '#ff0',
      },
    },
    buttonThird: {
      main: '#343A3F',
      contrastText: '#FFF',
      ':disabled': {
        backgroundColor: '#ff0',
      },
    },
    buttonSuccess: {
      main: '#12B347',
      contrastText: '#FFF',
      ':disabled': {
        backgroundColor: '#ff0',
      },
    },
    buttonDanger: {
      main: '#b14949',
      contrastText: '#FFF',
      ':disabled': {
        backgroundColor: '#ff0',
      },
    },
    buttonInfo: {
      main: '#E5F5FF',
      contrastText: '#FFF',
      ':disabled': {
        backgroundColor: '#ff0',
      },
    },
    buttonSecondary: {
      main: '#464255',
      second: '#cdcdcd',
      contrastText: '#FFF',
      ':disabled': {
        backgroundColor: '#ff0',
      },
    },
    badgePrimary: {
      main: '#58CDFF',
      contrastText: '#FFF',
    },
    iconButtonPrimary: {
      main: '#464255',
      light: '#FBFBFB',
      contrastText: '#fff',
    },
    appColor: {
      main: '#fff0',
      contrastText: '#464255',
    },
    info: {
      main: '#58CDFF',
    },
    success: {
      main: '#12B347',
    },
    danger: {
      main: '#c80000',
    },
    textButton: {
      main: '#000',
      contrastText: '#000',
      '&:hover': {
        main: '#464255',
        contrastText: '#464255',
      },
    },
    textColor: {
      light: '#fff',
    },
    pricingBoxColors: {
      largePackBackgroundColor: '#f0ffea',
      webPackBackgroundColor: '#fdeafe',
      singleImageBackgroundColor: '#ececec',
      largePackSelectedBackgroundColor: '#e5f2de',
      webPackSelectedBackgroundColor: '#f4e1f5',
      singleImageSelectedBackgroundColor: '#e0dddd',
    },
  },
  components: {
    // Name of the component
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          fontSize: '14px',
          borderRadius: '10px',
          boxShadow: 'none',
          textTransform: 'none',
          fontWeight: 600,
          padding: '10px 15px',
          margin: '0 5px',
          '&.buttonDense': {
            fontSize: '12px',
            padding: '5px 10px',
            marginRight: 1,
          },
          [theme.breakpoints.down('md')]: {
            fontSize: '12px',
            padding: '8px 12px',
            margin: '0 3px',
            '&.buttonDense': {
              fontSize: '10px',
              padding: '4px 9px',
            },
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          fontSize: '22px',
          borderRadius: '10px',
          padding: '10px 10px',
          margin: '0 0 0 20px',
          backgroundColor: '#fff',
          '&.dark:hover': {
            background: '#626262',
          },
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        // Name of the slot
        badge: {
          borderRadius: '6px',
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        // Name of the slot
        primary: {
          fontSize: '14px',
          [theme.breakpoints.down('md')]: {
            fontSize: '12px',
          },
        },
      },
    },
    // MuiOutlinedInput: {
    //   styleOverrides: {
    //     root: {
    //       '& input:disabled': {
    //         borderColor: 'red', // Change the border color to red for disabled state
    //         color: 'red', // Change the text color to red for disabled state
    //         background: 'red'
    //       },
    //     },
    //   },
    // },
    MuiTypography: {
      styleOverrides: {
        // Name of the slot
        paragraph: {
          marginBottom: '3px',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: '#000',
          },
          '&.Mui-selected.sidebar-item:hover': {
            backgroundColor: '#000',
          },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          paddingLeft: 7,
          paddingRight: 7,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: '#000',
          fontSize: '14px',
          '&.Mui-selected': {
            color: '#fff',
            backgroundColor: '#000',
          },
          '&.Add-border': {
            borderRight: '1px solid rgba(0, 0, 0, 0.1)',
            paddingRight: '34px',
            // marginRight: '16px',
            paddingLeft: '30px',
          },

          [theme.breakpoints.down('md')]: {
            fontSize: '12px',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          background: '#fff',
          fontSize: 14,
          '&:hover': {
            background: '#fff',
          },

          [theme.breakpoints.down('md')]: {
            fontSize: '12px',
          },
        },
        body: {
          fontSize: 13.5,

          [theme.breakpoints.down('md')]: {
            fontSize: '11.5px',
          },
        },
      },
    },
    MuiTimeline: {
      styleOverrides: {
        root: {
          padding: '0',
        },
      },
    },
    MuiTimelineItem: {
      styleOverrides: {
        root: {
          padding: '0',
          '&::before': {
            display: 'none',
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          margin: '5px 0',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '& fieldset': {
            borderColor: '#464255',
          },
          '& fieldset.rounded': {
            borderRadius: 16,
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: 16,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          '&.dense': {
            padding: '8.5px 14px',
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        '.py0': {
          paddingBottom: '0px !important',
          paddingTop: '0px !important',
        },
        '.fontWeight500': {
          fontWeight: '500 !important',
        },
        '.fontWeight600': {
          fontWeight: '600 !important',
        },
        '.colorWhite': {
          color: '#fff !important',
        },
        '.colorPrimary': {
          color: '#464255 !important',
        },
        '.colorGray': {
          color: '#BCBEBE !important',
        },
        '.colorBlack': {
          color: '#000 !important',
        },
        '.colorHiglight': {
          color: '#993300 !important',
        },
        '.colorHiglight2': {
          color: '#58CDFF !important',
        },
        '.sectionLeftMargin': {
          marginLeft: '30px',
          [theme.breakpoints.down('md')]: {
            marginLeft: '0px',
          },
        },
        '.my3': {
          marginTop: 16,
          marginBottom: 16,
        },
        '.mx3': {
          marginLeft: 16,
          marginRight: 16,
        },
        '.img-fluid': {
          width: '100%',
          height: 'auto',
        },
        '.rounded-2': {
          borderRadius: 8,
        },
        '.rounded-3': {
          borderRadius: 16,
        },
        '.borderBottom-1': {
          borderBottom: '1px solid #d7d7d7',
          marginTop: 5,
        },
        '.border-1': {
          border: '1px solid #d7d7d7',
          marginTop: 5,
        },
        '.maxWidth-25': {
          maxWidth: '25%',
          minWidth: '200px',
        },
        '.textDecoration-none': {
          textDecoration: 'none !important',
        },
        '.print:last-child': {
          pageBreakAfter: 'auto !important',
        },
        '.link-text': {
          display: 'inline-block',
          borderBottom: '1px solid',
          cursor: 'pointer',
          color: 'red',
        },
        '.text-center': {
          textAlign: 'center',
        },
        '.noMinWidth': {
          minWidth: 'auto !important',
        },
      },
    },
  },

  typography: {
    root: {
      marginBottom: 5,
    },
    h1: {
      fontSize: '26px',
      fontWeight: 500,

      [theme.breakpoints.down('md')]: {
        fontSize: '23px',
      },
    },
    h2: {
      fontSize: '22px',
      fontWeight: 500,

      [theme.breakpoints.down('md')]: {
        fontSize: '19px',
      },
    },
    h3: {
      fontSize: '18px',
      fontWeight: 500,

      [theme.breakpoints.down('md')]: {
        fontSize: '16px',
      },
    },
    h5: {
      fontSize: '16px',
      fontWeight: 500,

      [theme.breakpoints.down('md')]: {
        fontSize: '14px',
      },
    },
    h6: {
      fontSize: '14px',

      [theme.breakpoints.down('md')]: {
        fontSize: '12px',
      },
    },
    p: {
      fontSize: '13px',

      [theme.breakpoints.down('md')]: {
        fontSize: '12px',
      },
      '&.mediumFont': {
        fontSize: '14px',

        [theme.breakpoints.down('md')]: {
          fontSize: '13px',
        },
      },
    },
    '14A3': {
      fontSize: '13px',
      color: '#A3A3A3',

      [theme.breakpoints.down('md')]: {
        fontSize: '12px',
      },
    },
    '12A3': {
      fontSize: '12px',
      color: '#A3A3A3',

      [theme.breakpoints.down('md')]: {
        fontSize: '11px',
      },
    },
    '15px4625': {
      fontSize: '14px',
      color: '#464255',
      fontWeight: '500',

      [theme.breakpoints.down('md')]: {
        fontSize: '12px',
      },
    },
    mediumBold: {
      fontSize: '14px',
      fontWeight: '600',

      [theme.breakpoints.down('md')]: {
        fontSize: '12px',
      },
    },
    smallFont: {
      fontSize: '12px',
    },
    '25pxBold4625': {
      fontSize: '22px',
      color: '#464255',
      fontWeight: '600',

      [theme.breakpoints.down('md')]: {
        fontSize: '19px',
      },
    },
  },
  backgroundColor: {
    box: {
      main: '#fff',
      primary: '#b8dcfa26',
      dark: '#464255',
    },
  },
  shape: {
    borderRadius: (val) => 2 * val,
  },
});
