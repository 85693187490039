import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useSearchParams } from 'react-router-dom';

export default function CustomTabs({ tabList, tabClick, sx, managedTabs }) {
  const [value, setValue] = React.useState(1);
  const [searchParams, setSearchParams] = useSearchParams({ tab: 1 });
  const handleChange = (event, newValue) => {
    setValue(newValue);
    setSearchParams({
      tab: newValue,
    });
    generateTabClick(newValue);
  };

  const generateTabClick = (value) => {
    let data = tabList.filter((item) => item.id == value);
    tabClick(data[0]);
  };

  React.useEffect(() => {
    try {
      let tab = searchParams.get('tab');
      setValue(Number(tab) || 1);
      if (!managedTabs) {
        generateTabClick(Number(tab) || 1);
      }
    } catch (e) {}
  }, [searchParams]);

  return (
    <Box sx={{ widht: '100%', ...sx }}>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        // scrollButtons
        indicatorColor="#fff"
        allowScrollButtonsMobile
      >
        {tabList?.map((item, index) => {
          return (
            <Tab
              label={item.label}
              value={item.id}
              key={item.id}
              className={`${
                tabList.length > 1 && tabList.length - 1 != index
                  ? 'Add-border'
                  : ''
              }`}
            />
          );
        })}
      </Tabs>
    </Box>
  );
}
