import React, { useState } from 'react';
import CustomButton from '../../Components/CommonComp/Buttons/CustomButton';
import MainLayout from '../Layouts/MainLayout';
import Chalan from './Chalan';
import { useNavigate } from 'react-router-dom';
import GetBillingExcelModal from '../../Components/Modals/Billing/GetBillingExcelModal';

function ChalanContainer() {
  const [navParams, setNavParams] = useState({
    navTitle: 'All Chalan List Data',
    navSubTitle: 'Welcome to Classy Vouge',
  });
  const [openAddEditDialog, setOpenAddEditDialog] = useState(false);
  const navigate = useNavigate();
  const [addUserModal, setAddUserModal] = useState(false);
  const handleAddUserModal = (value = false) => {
    setAddUserModal(value);
  };
  const handleNavParams = (obj) => {
    setNavParams({
      ...navParams,
      ...obj,
    });
  };
  const handleAdd = (value) => {
    setOpenAddEditDialog(value);
  };
  return (
    <MainLayout
      navTitle={navParams.navTitle}
      navSubTitle={navParams.navSubTitle}
      navBarRightComp={
        <>
          <CustomButton
            sx={{ padding: '12px 15px', ml: (theme) => theme.spacing(2) }}
            color={'buttonPrimary'}
            onClick={() => {
              setOpenAddEditDialog(true);
            }}
          >
            Download Data
          </CustomButton>

          <CustomButton
            sx={{ padding: '12px 15px', ml: (theme) => theme.spacing(2) }}
            color={'buttonPrimary'}
            onClick={() => navigate('/chalan/addChalan')}
          >
            Add New Chalan
          </CustomButton>
        </>
      }
    >
      <Chalan
        handleNavParams={handleNavParams}
        addUserModal={addUserModal}
        handleAddUserModal={handleAddUserModal}
      />
      {openAddEditDialog && (
        <GetBillingExcelModal open={openAddEditDialog} handleOpen={handleAdd} />
      )}
    </MainLayout>
  );
}

export default ChalanContainer;
