import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  allChalanList: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
  heroChalanList: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
  singleChalanList: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
};

export const chalanSlice = createSlice({
  name: 'chalan',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setAllChalanListing: (state, action) => {
      state.allChalanList.items = action.payload;
      state.allChalanList.error = {
        isError: false,
        message: '',
      };
    },
    setAllChalanListingLoading: (state, action) => {
      state.allChalanList.loading = action.payload;
    },
    setHeroChalanListError: (state, action) => {
      state.heroChalanList.items = {};
      state.heroChalanList.error = action.payload;
    },

    setHeroChalanList: (state, action) => {
      state.heroChalanList.items = action.payload;
      state.heroChalanList.error = {
        isError: false,
        message: '',
      };
    },
    setHeroChalanListLoading: (state, action) => {
      state.heroChalanList.loading = action.payload;
    },
    setAllChalanListingError: (state, action) => {
      state.allChalanList.items = {};
      state.allChalanList.error = action.payload;
    },

    setSingleChalanListing: (state, action) => {
      state.singleChalanList.items = action.payload;
      state.singleChalanList.error = {
        isError: false,
        message: '',
      };
    },
    setSingleChalanListingLoading: (state, action) => {
      state.singleChalanList.loading = action.payload;
    },
    setSingleChalanListingError: (state, action) => {
      state.singleChalanList.items = {};
      state.singleChalanList.error = action.payload;
    },
  },
});

export default chalanSlice.reducer;

// Action creators are generated for each case reducer function
export const {
  setLoading,
  setAllChalanListing,
  setAllChalanListingLoading,
  setAllChalanListingError,
  setSingleChalanListing,
  setSingleChalanListingLoading,
  setSingleChalanListingError,
  setHeroChalanListError,
  setHeroChalanList,
  setHeroChalanListLoading,
} = chalanSlice.actions;

export const chalanItemsSelector = (state) => state.chalan;
