import axios from 'axios';
import { toast } from 'react-toastify';
import ApiConfig from '../config/ApiConfig';
import { errorMessage } from '../lib/helper';
import { STATUS_200, STATUS_300 } from '../data/constants';
import {
  setAllBillerPartyListing,
  setAllBillerPartyListingLoading,
  setAllBillerPartyListingError,
  setAllReceiverPartyListing,
  setAllReceiverPartyListingLoading,
  setAllReceiverPartyListingError,
  setSinglePartyListing,
  setSinglePartyListingLoading,
  setSinglePartyListingError,
} from '../redux/party';

export const getAllBillerPartyListing = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setAllBillerPartyListingLoading(true));
        const resp = await axios.get(`${ApiConfig.adminPartyLists}`, {
          params: payload,
        });
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setAllBillerPartyListing(resp.data.data));
          dispatch(setAllBillerPartyListingLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setAllBillerPartyListingError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setAllBillerPartyListingLoading(false));
      }
    };
  }
};

export const getAllReceiverPartyListing = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setAllReceiverPartyListingLoading(true));
        const resp = await axios.get(`${ApiConfig.adminPartyLists}`, {
          params: payload,
        });
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setAllReceiverPartyListing(resp.data.data));
          dispatch(setAllReceiverPartyListingLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setAllReceiverPartyListingError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setAllReceiverPartyListingLoading(false));
      }
    };
  }
};

export const getSinglePartyListing = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setSinglePartyListingLoading(true));
        const resp = await axios.get(`${ApiConfig.adminPartyLists}`, {
          params: payload,
        });
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setSinglePartyListing(resp.data.data));
          dispatch(setSinglePartyListingLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setSinglePartyListingError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setSinglePartyListingLoading(false));
      }
    };
  }
};

export const getPartyBySearch = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.get(`${ApiConfig.adminPartyLists}`, {
        params: payload,
      });
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        return resp?.data;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};

export const createPartyDetail = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.adminCreateParty}`, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp.data.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};

export const updatePartyDetail = async (payload, userId) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.put(
        `${ApiConfig.adminUpdateParty}/${userId}`,
        payload,
      );
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp?.data?.message, {
          autoClose: 2500,
        });
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
    }
  }
};

export const deletePartyDetail = async (partyId) => {
  if (partyId !== undefined) {
    try {
      const resp = await axios.delete(
        `${ApiConfig.adminDeleteParty}/${partyId}`,
      );
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp?.data?.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};
