import { Box, Grid, Typography } from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import CustomBox from '../CommonComp/CustomBox/CustomBox';
import { DATA_LIMIT } from '../../data/constants';
import * as Yup from 'yup';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import CustomCheckbox from '../CommonComp/CustomInput/CheckboxField';
import Divder from '../CommonComp/Divder';
import Loader from '../CommonComp/CustomLoading/Loader';
import { getPartyBySearch } from '../../Services/partyApis';
import { useNavigate } from 'react-router-dom';
import MatTable from '../Tables/MatTable';
import {
  createFabricIssueDetail,
  updateFabricIssueDetail,
} from '../../Services/fabricIssueApis';
import CustomAsyncSelect from '../CommonComp/CustomInput/CustomAsyncSelect';
import { getDesignBySearch } from '../../Services/designApis';
import { fabricIssueItemsSelector } from '../../redux/fabricIssue';
import { getSingleFabricIssueListing } from '../../Services/fabricIssueApis';

const limit = DATA_LIMIT;

function FabricIssueDetailPage({ fabricIssueId, isEdit }) {
  const { singleFabricIssueList } = useSelector(fabricIssueItemsSelector);
  const [isLoadingState, setIsLoadingState] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [mainDataOfProduct, setMainDataOfProfuct] = useState({});
  const handleConfirm = async (values, { resetForm }) => {
    setIsLoadingState(true);
    const mainPayload = {
      fabricList: [],
      billerParty: '',
      receiverParty: '',
      isActive: true,
    };
    if (values?.billerParty) {
      mainPayload.billerParty = values?.billerParty?.value;
    }
    if (values?.billerParty) {
      mainPayload.receiverParty = values?.receiverParty?.value;
    }
    if (values?.fabricList && values?.fabricList?.length > 0) {
      const getLatestPayloadForDesignData = await values?.fabricList?.map(
        (data) => {
          let tempCurrentValue = {
            designName: data?.designName?.value,
            fabricQuantity: Number(data?.fabricQuantity),
          };
          return tempCurrentValue;
        },
      );
      mainPayload.fabricList = getLatestPayloadForDesignData;
    }
    if (isEdit) {
      let resp = await updateFabricIssueDetail(mainPayload, fabricIssueId);
      if (resp) {
        getFabricIssueDetails();
      }
      setIsLoadingState(false);
    } else {
      let resp = await createFabricIssueDetail(mainPayload);
      if (resp) {
        resetForm({
          billerParty: {
            value: '6561fa6c21c3b1000816f915',
            label: 'Kuberji Branch (Classy Vouge)',
          },
          receiverParty: '',
          isActive: Boolean(true),
          fabricList: [
            {
              designName: '',
              fabricQuantity: 0,
            },
          ],
        });
      }
      setIsLoadingState(false);
      navigate('/fabricIssue');
    }
  };
  const getFabricIssueDetails = async () => {
    const payload = {
      page: String(1),
      limit: limit,
      fabricIssueId: isEdit ? fabricIssueId : '',
    };
    if (isEdit) {
      dispatch(getSingleFabricIssueListing(payload));
    }
  };
  useEffect(() => {
    if (isEdit) {
      getFabricIssueDetails();
    }
  }, []);
  useEffect(() => {
    if (
      singleFabricIssueList?.items &&
      Object.keys(singleFabricIssueList?.items)?.length > 0
    ) {
      const billerParty = {
        value: singleFabricIssueList?.items?.billerParty?._id,
        label: `${singleFabricIssueList?.items?.billerParty?.name} (${singleFabricIssueList?.items?.billerParty?.organizationName})`,
      };
      const receiverParty = {
        value: singleFabricIssueList?.items?.receiverParty?._id,
        label: `${singleFabricIssueList?.items?.receiverParty?.name} (${singleFabricIssueList?.items?.receiverParty?.organizationName})`,
      };
      const fabricList = singleFabricIssueList?.items?.fabricList?.map(
        (data) => {
          return {
            designName: {
              value: data?.designName?._id,
              label: `${data?.designName?.sku}`,
            },
            fabricQuantity: data?.fabricQuantity,
          };
        },
      );
      setMainDataOfProfuct({
        ...singleFabricIssueList?.items,
        billerParty,
        receiverParty,
        fabricList,
      });
      // setMainDataOfProfuct({});
    }
  }, [singleFabricIssueList?.items]);
  const formik = useFormik({
    initialValues: {
      billerParty: isEdit
        ? mainDataOfProduct?.billerParty || {
            value: '6561fa6c21c3b1000816f915',
            label: 'Kuberji Branch (Classy Vouge)',
          }
        : {
            value: '6561fa6c21c3b1000816f915',
            label: 'Kuberji Branch (Classy Vouge)',
          },
      receiverParty: isEdit ? mainDataOfProduct?.receiverParty || '' : '',
      isActive: isEdit
        ? Boolean(mainDataOfProduct?.isActive) || Boolean(true)
        : Boolean(true),
      fabricList: isEdit
        ? mainDataOfProduct?.fabricList || [
            {
              designName: mainDataOfProduct?.fabricList,
              fabricQuantity: 0,
            },
          ]
        : [
            {
              designName: '',
              fabricQuantity: 0,
            },
          ],
    },
    validationSchema: Yup.object({
      isActive: Yup.string().required('Is Active is required.'),
      billerParty: Yup.object().required('Biller Party is required'),
      receiverParty: Yup.object().required('Receiver Party is required'),
      fabricList: Yup.array().of(
        Yup.object().shape({
          designName: Yup.object().required('product Data is required'),
        }),
      ),
    }),
    enableReinitialize: true,
    onSubmit: handleConfirm,
  });
  const isValidData = () => {
    // if(formik.values?.fabricList)
    return true;
  };

  const columnForPrivacyPolicyData = [
    {
      label: 'Product SKU',
      id: 'designName',
      format: (columnData, rowIndex, formatCallback, arr, errors, row) => (
        <span
          style={{
            display: 'inline-block',
            whiteSpace: 'nowrap',
            width: '100%',
            minWidth: '200px',
          }}
        >
          <CustomAsyncSelect
            promiseOptions={getProductListing}
            // error={error}
            isMulti={false}
            // helperText={helperText}
            closeMenuOnSelect={false}
            controlStyle={{
              padding: 9,
            }}
            cacheOptions={true}
            // value={fabricListData}
            // handleChange={(e) => {
            //   setfabricListData(e);
            // }}
            width={'100%'}
            dropDownZIndex={15000}
            labelShrink={true}
            label="Search"
            id={`fabricList[${rowIndex}].designName`}
            name={`fabricList[${rowIndex}].designName`}
            value={formik.values.fabricList[rowIndex]?.designName}
            onChange={(e) =>
              formik.setFieldValue(`fabricList[${rowIndex}].designName`, e)
            }
            error={
              formik.touched.fabricList &&
              formik.touched.fabricList[rowIndex]?.designName &&
              formik.errors.fabricList &&
              formik.errors.fabricList[rowIndex]?.designName
                ? true
                : false
            }
            helperText={
              formik.touched.fabricList &&
              formik.touched.fabricList[rowIndex]?.designName &&
              formik.errors.fabricList &&
              formik.errors.fabricList[rowIndex]?.designName
            }
          />
        </span>
      ),
    },
    {
      label: 'Total Meter',
      id: 'designName',
      format: (columnData, rowIndex, formatCallback, arr, errors, row) => (
        <span
          style={{
            display: 'inline-block',
            whiteSpace: 'nowrap',
            width: '100%',
          }}
        >
          <CustomInput
            variant="outlined"
            sx={{ minWidth: 100 }}
            label="Value"
            placeholder="Enter Value"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              classes: {
                notchedOutline: 'rounded',
              },
            }}
            // disabled={!isValueInArray(rowIndex, 'XS')}
            id={`fabricList[${rowIndex}].fabricQuantity`}
            name={`fabricList[${rowIndex}].fabricQuantity`}
            value={formik.values.fabricList[rowIndex]?.fabricQuantity}
            onChange={(e) =>
              formik.setFieldValue(
                `fabricList[${rowIndex}].fabricQuantity`,
                e.target.value,
              )
            }
            error={
              formik.touched.fabricList &&
              formik.touched.fabricList[rowIndex]?.fabricQuantity &&
              formik.errors.fabricList &&
              formik.errors.fabricList[rowIndex]?.fabricQuantity
                ? true
                : false
            }
            helperText={
              formik.touched.fabricList &&
              formik.touched.fabricList[rowIndex]?.fabricQuantity &&
              formik.errors.fabricList &&
              formik.errors.fabricList[rowIndex]?.fabricQuantity
            }
          />
        </span>
      ),
    },
  ];
  const actionBodyForPrivacyPolicyData = (data, rowIndex) => {
    return (
      <>
        <div style={{ display: 'flex' }}>
          <CustomButton
            onClick={() => handleRemoveNewPrivacyPolicyData(data, rowIndex)}
            sx={{
              padding: '5px 10px',
              marginRight: 1,
              fontSize: '12px',
              bgcolor: 'buttonDanger.main',
              color: 'buttonSecondary.contrastText',
            }}
          >
            Delete
          </CustomButton>
        </div>
      </>
    );
  };

  const handleAddNewPrivacyPolicyData = (value) => {
    let tempArray = [...formik.values?.fabricList];
    let emptyObj = {
      designName: '',
      fabricQuantity: 0,
    };
    tempArray?.push(emptyObj);
    formik.setValues({ ...formik.values, fabricList: tempArray });
  };
  const handleRemoveNewPrivacyPolicyData = (data, rowIndex) => {
    let tempArray = [...formik?.values?.fabricList];
    tempArray.splice(rowIndex, 1);
    formik.setValues({ ...formik?.values, fabricList: tempArray });
  };
  const getProductListing = async (inputValue) => {
    if (inputValue && inputValue.length > 1) {
      const payload = {
        page: String(1),
        limit: 10,
        search: inputValue,
      };
      let data = await getDesignBySearch(payload);
      let updatedData = data?.data?.results?.map((item, index) => {
        return {
          value: item?._id,
          label: `${item?.sku}`,
          ...item,
        };
      });
      return updatedData || [];
    }
  };
  const getBillerPartyListing = async (inputValue) => {
    if (inputValue && inputValue.length > 1) {
      const payload = {
        page: String(1),
        limit: 10,
        search: inputValue,
        partyType: 'Biller',
      };
      let data = await getPartyBySearch(payload);
      let updatedData = data?.data?.results?.map((item, index) => {
        return {
          value: item?._id,
          label: `${item?.name} (${item?.organizationName})`,
        };
      });
      return updatedData || [];
    }
  };
  const getReceiverPartyListing = async (inputValue) => {
    if (inputValue && inputValue.length > 1) {
      const payload = {
        page: String(1),
        limit: 10,
        search: inputValue,
        partyType: 'Receiver',
      };
      let data = await getPartyBySearch(payload);
      let updatedData = data?.data?.results?.map((item, index) => {
        return {
          value: item?._id,
          label: `${item?.name} (${item?.organizationName})`,
        };
      });
      return updatedData || [];
    }
  };
  return (
    <div>
      {(singleFabricIssueList?.loading || isLoadingState) && <Loader />}
      <form onSubmit={formik.handleSubmit}>
        <CustomBox padding={3}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <CustomAsyncSelect
                promiseOptions={getBillerPartyListing}
                isMulti={false}
                closeMenuOnSelect={false}
                controlStyle={{
                  padding: 9,
                }}
                cacheOptions={true}
                width={'100%'}
                dropDownZIndex={15000}
                labelShrink={true}
                label="Biller Party Search"
                defaultValue={[
                  {
                    value: '6546046c55e8814cfcbab9fa',
                    label: 'Keyur Bhai (Classy Vouge Pvt Ltd)',
                  },
                ]}
                id={`billerParty`}
                name={`billerParty`}
                value={formik.values.billerParty}
                onChange={(e) => formik.setFieldValue(`billerParty`, e)}
                error={
                  formik.touched.billerParty &&
                  Boolean(formik.errors.billerParty)
                }
                helperText={
                  formik.touched.billerParty && formik.errors.billerParty
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomAsyncSelect
                promiseOptions={getReceiverPartyListing}
                isMulti={false}
                closeMenuOnSelect={false}
                controlStyle={{
                  padding: 9,
                }}
                cacheOptions={true}
                width={'100%'}
                dropDownZIndex={15000}
                labelShrink={true}
                label="Receiver Party Search"
                id={`receiverParty`}
                name={`receiverParty`}
                value={formik.values.receiverParty}
                onChange={(e) => formik.setFieldValue(`receiverParty`, e)}
                error={
                  formik.touched.receiverParty &&
                  Boolean(formik.errors.receiverParty)
                }
                helperText={
                  formik.touched.receiverParty && formik.errors.receiverParty
                }
              />
            </Grid>
            <Grid item xs={12}>
              <CustomCheckbox
                label={'Is Active'}
                labelPlacement={'end'}
                subLableText={'Checked'}
                handleChange={(e) => {
                  formik.setFieldValue(
                    'isActive',
                    e.target.checked == true ? true : false,
                  );
                }}
                name="isActive"
                value={Boolean(formik.values.isActive)}
                error={
                  formik.touched.isActive && Boolean(formik.errors.isActive)
                }
                helperText={formik.touched.isActive && formik.errors.isActive}
              />
            </Grid>
          </Grid>
        </CustomBox>
        <Divder spacing={1} />
        <CustomBox padding={0}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              px: (theme) => theme.spacing(3),
              py: (theme) => theme.spacing(2),
            }}
          >
            <Typography variant="h3" sx={{}}>
              Select Product Design & Add Sizes
            </Typography>
            <CustomButton
              className="buttonDense"
              onClick={() => handleAddNewPrivacyPolicyData(true)}
              color={'buttonPrimary'}
            >
              Add New Design
            </CustomButton>
          </Box>
          <Divder spacing={1} />
          <MatTable
            showCheckbox={false}
            columns={columnForPrivacyPolicyData}
            data={formik.values.fabricList || []}
            action={actionBodyForPrivacyPolicyData}
            total={0}
            pagination={false}
          />
        </CustomBox>
        <CustomButton
          sx={{ marginTop: 2 }}
          type="submit"
          color={'buttonPrimary'}
          disabled={!isValidData()}
        >
          Submit
        </CustomButton>
      </form>
    </div>
  );
}

export default FabricIssueDetailPage;
