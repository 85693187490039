import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  allUserList: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
  singleUserList: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
  addressList: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
  wishlistData: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
  cartList: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
  couponsList: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setAllUserListing: (state, action) => {
      state.allUserList.items = action.payload;
      state.allUserList.error = {
        isError: false,
        message: '',
      };
    },
    setAllUserListingLoading: (state, action) => {
      state.allUserList.loading = action.payload;
    },
    setAllUserListingError: (state, action) => {
      state.allUserList.items = {};
      state.allUserList.error = action.payload;
    },

    setSingleUserListing: (state, action) => {
      state.singleUserList.items = action.payload;
      state.singleUserList.error = {
        isError: false,
        message: '',
      };
    },
    setSingleUserListingLoading: (state, action) => {
      state.singleUserList.loading = action.payload;
    },
    setSingleUserListingError: (state, action) => {
      state.singleUserList.items = {};
      state.singleUserList.error = action.payload;
    },

    setAddressListing: (state, action) => {
      state.addressList.items = action.payload;
      state.addressList.error = {
        isError: false,
        message: '',
      };
    },
    setAddressListingLoading: (state, action) => {
      state.addressList.loading = action.payload;
    },
    setAddressListingError: (state, action) => {
      state.addressList.items = {};
      state.addressList.error = action.payload;
    },

    setWishlistListing: (state, action) => {
      state.wishlistData.items = action.payload;
      state.wishlistData.error = {
        isError: false,
        message: '',
      };
    },
    setWishlistListingLoading: (state, action) => {
      state.wishlistData.loading = action.payload;
    },
    setWishlistListingError: (state, action) => {
      state.wishlistData.items = {};
      state.wishlistData.error = action.payload;
    },

    setCartListing: (state, action) => {
      state.cartList.items = action.payload;
      state.cartList.error = {
        isError: false,
        message: '',
      };
    },
    setCartListingLoading: (state, action) => {
      state.cartList.loading = action.payload;
    },
    setCartListingError: (state, action) => {
      state.cartList.items = {};
      state.cartList.error = action.payload;
    },

    setCouponsListing: (state, action) => {
      state.couponsList.items = action.payload;
      state.couponsList.error = {
        isError: false,
        message: '',
      };
    },
    setCouponsListingLoading: (state, action) => {
      state.couponsList.loading = action.payload;
    },
    setCouponsListingError: (state, action) => {
      state.couponsList.items = {};
      state.couponsList.error = action.payload;
    },

    clearSingleUserDetails: (state, action) => {
      state.allUserList.items = {};
    },
  },
});

export default userSlice.reducer;

// Action creators are generated for each case reducer function
export const {
  setLoading,
  setAllUserListing,
  setAllUserListingLoading,
  setAllUserListingError,
  clearSingleUserDetails,
  setSingleUserListing,
  setSingleUserListingLoading,
  setSingleUserListingError,
  setAddressListing,
  setAddressListingLoading,
  setAddressListingError,
  setWishlistListing,
  setWishlistListingLoading,
  setWishlistListingError,
  setCartListing,
  setCartListingLoading,
  setCartListingError,
  setCouponsListing,
  setCouponsListingLoading,
  setCouponsListingError,
} = userSlice.actions;

export const userItemsSelector = (state) => state.user;
