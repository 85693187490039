import React from 'react';
import CustomBox from '../CommonComp/CustomBox/CustomBox';
import AllFabricIssueList from './AllFabricIssueList';

function FabricIssueTabBody({ activeTab }) {
  return (
    <CustomBox
      padding={0}
      sx={{
        minHeight: 200,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {activeTab == 1 && <AllFabricIssueList />}
    </CustomBox>
  );
}

export default FabricIssueTabBody;
