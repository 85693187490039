import { Box, Grid } from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import CustomBox from '../CommonComp/CustomBox/CustomBox';
import { DATA_LIMIT } from '../../data/constants';
import * as Yup from 'yup';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import CustomCheckbox from '../CommonComp/CustomInput/CheckboxField';
import Divder from '../CommonComp/Divder';
import Loader from '../CommonComp/CustomLoading/Loader';
import {
  createPartyDetail,
  getSinglePartyListing,
  updatePartyDetail,
} from '../../Services/partyApis';
import { partyItemsSelector } from '../../redux/party';
import { useNavigate } from 'react-router-dom';
import { width } from '@mui/system';
import { getSingleChalanListing } from '../../Services/chalanApis';
import { chalanItemsSelector } from '../../redux/chalan';
import { formatDate } from '../../lib/helper';

const limit = DATA_LIMIT;

export const ChalanInvoicePage = React.forwardRef(
  ({ billingId, applyPageBreak }, ref) => {
    // function ChalanInvoicePage({ billingId,  ref }) {
    const { singleChalanList } = useSelector(chalanItemsSelector);
    const [isLoadingState, setIsLoadingState] = useState(false);
    const [sizeWiseDynamicHeader, setSizeWiseDynamicHeader] = useState([]);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [mainDataOfProduct, setMainDataOfProfuct] = useState({});
    // const [applyPageBreak, setApplyPageBreak] = useState(false);
    useEffect(() => {
      if (
        singleChalanList?.items &&
        Object.keys(singleChalanList?.items)?.length > 0
      ) {
        const designDataList = [...singleChalanList?.items?.designList];
        const sortOrder = [
          'XS',
          'S',
          'M',
          'L',
          'XL',
          'XXL',
          '3XL',
          '4XL',
          '5XL',
        ];
        const uniqueSizes = [
          ...new Set(
            designDataList.flatMap((item) =>
              item.designData.map((d) => d.size),
            ),
          ),
        ].sort((a, b) => {
          return sortOrder.indexOf(a) - sortOrder.indexOf(b);
        });
        setSizeWiseDynamicHeader(uniqueSizes);
        setMainDataOfProfuct(singleChalanList?.items);
        // setMainDataOfProfuct({});
      }
    }, [singleChalanList?.items]);
    const getSingleBillingDetails = async () => {
      const payload = {
        page: String(1),
        limit: limit,
        billingId: billingId,
      };
      dispatch(getSingleChalanListing(payload));
    };
    useEffect(() => {
      getSingleBillingDetails();
    }, [billingId]);
    return (
      <div>
        {(singleChalanList?.loading || isLoadingState) && <Loader />}
        <CustomBox
          padding={3}
          sx={{
            overflowX: 'scroll',
          }}
        >
          <Box
            component={'div'}
            ref={ref}
            id="total-page-print"
            sx={{
              width: '930px',
              marginBottom: '15px',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          >
            <table id="table-1-print" style={{ width: '100%' }}>
              <tbody>
                <tr className="mainBillTitleHeader">
                  <td>Bill of Supply</td>
                </tr>
                <tr className="mainBillCompName">
                  {String(
                    singleChalanList?.items?.billerParty?.organizationName,
                  )?.toUpperCase()}
                </tr>
                <tr
                  style={{
                    borderTop: '2px solid black',
                    borderLeft: '2px solid black',
                    borderRight: '2px solid black',
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
                    padding: '10px 10px 2px',
                    gap: '15px',
                  }}
                >
                  <div className="fontBold-600 textSize-16">
                    <span
                      style={{
                        display: 'inline-block',
                        width: '150px',
                        textWrap: 'nowrap',
                      }}
                    >
                      Receiver Name:
                    </span>
                    <span>
                      {String(
                        singleChalanList?.items?.receiverParty?.name,
                      )?.toUpperCase()}
                    </span>
                  </div>
                  <div className="fontBold-600 textSize-16">
                    <span
                      style={{
                        display: 'inline-block',
                        width: '150px',
                        textWrap: 'nowrap',
                      }}
                    >
                      Date:
                    </span>
                    <span>
                      {formatDate(
                        singleChalanList?.items?.customCreatedAt ||
                          singleChalanList?.items?.createdAt,
                        'dd-mm-yyyy hh:mm 12h',
                      ) || '-'}
                    </span>
                  </div>
                </tr>
                <tr
                  style={{
                    borderLeft: '2px solid black',
                    borderRight: '2px solid black',
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
                    padding: '0 10px 5px',
                    gap: '15px',
                  }}
                >
                  <div className="fontBold-600 textSize-16">
                    <span
                      style={{
                        display: 'inline-block',
                        width: '150px',
                        textWrap: 'nowrap',
                      }}
                    >
                      Company:
                    </span>
                    <span>
                      {String(
                        singleChalanList?.items?.receiverParty
                          ?.organizationName,
                      )?.toUpperCase()}
                    </span>
                  </div>
                  <div className="fontBold-600 textSize-16">
                    <span
                      style={{
                        display: 'inline-block',
                        width: '150px',
                        textWrap: 'nowrap',
                      }}
                    >
                      Invoice No:
                    </span>
                    <span> {String(singleChalanList?.items?.billingNo)}</span>
                  </div>
                </tr>
                <tr
                  style={{
                    borderTop: '2px solid black',
                    borderLeft: '2px solid black',
                    borderRight: '2px solid black',
                    display: 'block',
                  }}
                >
                  <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                    <thead
                      style={{
                        backgroundColor: '#d4d4d4',
                        borderBottom: '2px solid black',
                      }}
                      className="fontBold-600 textSize-16"
                    >
                      <tr>
                        <td className="table-td border-right-2">Sr</td>
                        <td className="table-td border-right-2">Design SKU</td>
                        {sizeWiseDynamicHeader?.map((data, index) => {
                          return (
                            <td key={index} className="table-td border-right-2">
                              {data}
                            </td>
                          );
                        })}
                        <td className="table-td">Total</td>
                      </tr>
                    </thead>
                    <tbody
                      style={{
                        minHeight: '500px',
                        borderBottom: '2px solid black',
                      }}
                    >
                      {mainDataOfProduct?.designList?.map((data, index) => {
                        return (
                          <tr>
                            <td className="table-td-body border-right-2">
                              {index + 1}
                            </td>
                            <td className="table-td-body border-right-2">
                              {data?.designName?.sku}
                            </td>
                            {sizeWiseDynamicHeader.map((size, subIndex) => {
                              const matchingData = data.designData.find(
                                (d) => d.size == size,
                              );
                              return (
                                <td
                                  key={subIndex}
                                  className="table-td-body border-right-2"
                                >
                                  {matchingData ? matchingData.quantity : 0}
                                </td>
                              );
                            })}
                            <td className="table-td-body">
                              {data?.designTotalQuantity}
                            </td>
                          </tr>
                        );
                      })}
                      <tr
                        style={{ borderTop: '2px solid black' }}
                        className="fontBold-600 textSize-16"
                      >
                        <td className="table-td"></td>
                        <td className="table-td"></td>
                        {sizeWiseDynamicHeader?.map((data, thirdIndex) => {
                          return (
                            <td key={thirdIndex} className="table-td"></td>
                          );
                        })}
                        <td className="table-td">
                          Total Psc: {mainDataOfProduct?.totalBillQuantity}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </tr>
                <tr
                  style={{
                    borderLeft: '2px solid black',
                    borderRight: '2px solid black',
                    borderBottom: '2px solid black',
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
                    gap: '15px',
                    minHeight: '50px',
                  }}
                  className="fontBold-600 textSize-16"
                >
                  <div
                    style={{
                      padding: '8px 10px',
                      // borderRight: '2px solid black',
                      fontWeight: 400,
                    }}
                  >
                    <b>Notes:</b> {mainDataOfProduct?.notes}
                  </div>
                  <div
                  // style={{
                  //   display: 'flex',
                  //   justifyContent: 'space-between',
                  //   padding: '8px 10px',
                  // }}
                  >
                    {/* <div>Total Psc:</div>
                    <div style={{ minWidth: '100px' }}>
                      {mainDataOfProduct?.totalBillQuantity}
                    </div> */}
                  </div>
                </tr>
              </tbody>
            </table>
            <div
              style={{
                marginTop: '15px ',
                marginBottom: '15px ',
                borderBottom: '3px dashed black',
              }}
            ></div>
            <table id="table-2-print" style={{ width: '100%' }}>
              <tbody>
                <tr className="mainBillTitleHeader">
                  <td>Bill of Supply</td>
                </tr>
                <tr className="mainBillCompName">
                  {String(
                    singleChalanList?.items?.billerParty?.organizationName,
                  )?.toUpperCase()}
                </tr>
                <tr
                  style={{
                    borderTop: '2px solid black',
                    borderLeft: '2px solid black',
                    borderRight: '2px solid black',
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
                    padding: '10px 10px 2px',
                    gap: '15px',
                  }}
                >
                  <div className="fontBold-600 textSize-16">
                    <span
                      style={{
                        display: 'inline-block',
                        width: '150px',
                        textWrap: 'nowrap',
                      }}
                    >
                      Receiver Name:
                    </span>
                    <span>
                      {String(
                        singleChalanList?.items?.receiverParty?.name,
                      )?.toUpperCase()}
                    </span>
                  </div>
                  <div className="fontBold-600 textSize-16">
                    <span
                      style={{
                        display: 'inline-block',
                        width: '150px',
                        textWrap: 'nowrap',
                      }}
                    >
                      Date:
                    </span>
                    <span>
                      {formatDate(
                        singleChalanList?.items?.customCreatedAt ||
                          singleChalanList?.items?.createdAt,
                        'dd-mm-yyyy hh:mm 12h',
                      ) || '-'}
                    </span>
                  </div>
                </tr>
                <tr
                  style={{
                    borderLeft: '2px solid black',
                    borderRight: '2px solid black',
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
                    padding: '0 10px 5px',
                    gap: '15px',
                  }}
                >
                  <div className="fontBold-600 textSize-16">
                    <span
                      style={{
                        display: 'inline-block',
                        width: '150px',
                        textWrap: 'nowrap',
                      }}
                    >
                      Company:
                    </span>
                    <span>
                      {String(
                        singleChalanList?.items?.receiverParty
                          ?.organizationName,
                      )?.toUpperCase()}
                    </span>
                  </div>
                  <div className="fontBold-600 textSize-16">
                    <span
                      style={{
                        display: 'inline-block',
                        width: '150px',
                        textWrap: 'nowrap',
                      }}
                    >
                      Invoice No:
                    </span>
                    <span> {String(singleChalanList?.items?.billingNo)}</span>
                  </div>
                </tr>
                <tr
                  style={{
                    borderTop: '2px solid black',
                    borderLeft: '2px solid black',
                    borderRight: '2px solid black',
                    display: 'block',
                  }}
                >
                  <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                    <thead
                      style={{
                        backgroundColor: '#d4d4d4',
                        borderBottom: '2px solid black',
                      }}
                      className="fontBold-600 textSize-16"
                    >
                      <tr>
                        <td className="table-td border-right-2">Sr</td>
                        <td className="table-td border-right-2">Design SKU</td>
                        {sizeWiseDynamicHeader?.map((data, index) => {
                          return (
                            <td key={index} className="table-td border-right-2">
                              {data}
                            </td>
                          );
                        })}
                        <td className="table-td">Total</td>
                      </tr>
                    </thead>
                    <tbody
                      style={{
                        minHeight: '500px',
                        borderBottom: '2px solid black',
                      }}
                    >
                      {mainDataOfProduct?.designList?.map((data, index) => {
                        return (
                          <tr>
                            <td className="table-td-body border-right-2">
                              {index + 1}
                            </td>
                            <td className="table-td-body border-right-2">
                              {data?.designName?.sku}
                            </td>
                            {sizeWiseDynamicHeader.map((size, subIndex) => {
                              const matchingData = data.designData.find(
                                (d) => d.size == size,
                              );
                              return (
                                <td
                                  key={subIndex}
                                  className="table-td-body border-right-2"
                                >
                                  {matchingData ? matchingData.quantity : 0}
                                </td>
                              );
                            })}
                            <td className="table-td-body">
                              {data?.designTotalQuantity}
                            </td>
                          </tr>
                        );
                      })}
                      <tr
                        style={{ borderTop: '2px solid black' }}
                        className="fontBold-600 textSize-16"
                      >
                        <td className="table-td"></td>
                        <td className="table-td"></td>
                        {sizeWiseDynamicHeader?.map((data, thirdIndex) => {
                          return (
                            <td key={thirdIndex} className="table-td"></td>
                          );
                        })}
                        <td className="table-td">
                          Total Psc: {mainDataOfProduct?.totalBillQuantity}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </tr>
                <tr
                  style={{
                    borderLeft: '2px solid black',
                    borderRight: '2px solid black',
                    borderBottom: '2px solid black',
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
                    gap: '15px',
                    minHeight: '50px',
                  }}
                  className="fontBold-600 textSize-16"
                >
                  <div
                    style={{
                      padding: '8px 10px',
                      // borderRight: '2px solid black',
                      fontWeight: 400,
                    }}
                  >
                    <b>Notes:</b> {mainDataOfProduct?.notes}
                  </div>
                  <div
                  // style={{
                  //   display: 'flex',
                  //   justifyContent: 'space-between',
                  //   padding: '8px 10px',
                  // }}
                  >
                    {/* <div>Total Psc:</div>
                    <div style={{ minWidth: '100px' }}>
                      {mainDataOfProduct?.totalBillQuantity}
                    </div> */}
                  </div>
                </tr>
              </tbody>
            </table>
          </Box>
          <style>
            {`
                .mainBillTitleHeader{
                  padding: 5px 0;
                  border: 2px solid black;
                  text-align: center;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  background: #d4d4d4;
                }
                .mainBillTitleHeader td{
                  font-size: 18px;
                  font-weight: 600;
                }
                .mainBillCompName{
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  border-left: 2px solid black;
                  border-right: 2px solid black;
                  padding: 4px 0;
                  font-size: 22px;
                  font-weight: 600;
                }
                .mainBillAddName{
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  border-left: 2px solid black;
                  border-right: 2px solid black;
                  padding: 2px 0 10px;
                  font-size: 14px;
                  font-weight: 500;
                }
                .fontBold-600{
                  font-weight: 600;
                }
                .textSize-16{
                  font-size: 16px;
                }
                .textSize-18{
                  font-size: 18px;
                }
                .border-top{
                  border-top: 2px solid black;
                }
                .border-left{
                  border-left: 2px solid black;
                }
                .border-bottom{
                  border-bottom: 2px solid black;
                }
                .border-right{
                  border-right: 2px solid black;
                }
                .border-right-2{
                  border-right: 2px solid black;
                }
                .table-td{
                  padding: 2px 10px;
                }
                .table-td-body{
                  padding: 5px 10px;
                }
                @media print {
                  body {
                    -webkit-print-color-adjust: exact;
                    -moz-print-color-adjust: exact;
                    print-color-adjust: exact;
                  }
                }
                @media print {
                  #table-2-print {
                    page-break-before: ${
                      applyPageBreak == true ? 'always' : 'auto'
                    };
                  }
                }
              `}
          </style>
        </CustomBox>
      </div>
    );
  },
);

// export default ChalanInvoicePage;
