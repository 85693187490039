import React, { useState } from 'react';
import MainLayout from '../Layouts/MainLayout';
import BillerPartyDetails from './BillerPartyDetails';

function BillerPartyDetailsContainer({ isEdit }) {
  return (
    <MainLayout
      navTitle={'Biller Party Details'}
      navSubTitle={'Welcome to Classy Vouge'}
    >
      <BillerPartyDetails isEdit={isEdit} />
    </MainLayout>
  );
}

export default BillerPartyDetailsContainer;
