import { configureStore } from '@reduxjs/toolkit';

import loginReducer from './login';
import userReducer from './user';
import dashboardReducer from './dashboard';
import appSettingReducer from './appSetting';
import partyReducer from './party';
import designReducer from './design';
import chalanReducer from './chalan';
import fabricIssueReducer from './fabricIssue';
import jobWorkReducer from './jobWork';

export const store = configureStore({
  reducer: {
    loginUserData: loginReducer,
    user: userReducer,
    dashboardCount: dashboardReducer,
    appSetting: appSettingReducer,
    party: partyReducer,
    design: designReducer,
    chalan: chalanReducer,
    fabricIssue: fabricIssueReducer,
    jobWork: jobWorkReducer,
  },
});
