import axios from 'axios';
import { toast } from 'react-toastify';
import ApiConfig from '../config/ApiConfig';
import { errorMessage } from '../lib/helper';
import { STATUS_200, STATUS_300 } from '../data/constants';
import {
  setAllJobWorkListing,
  setAllJobWorkListingLoading,
  setAllJobWorkListingError,
  setSingleJobWorkListing,
  setSingleJobWorkListingLoading,
  setSingleJobWorkListingError,
} from '../redux/jobWork';

export const getAllJobWorkListing = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setAllJobWorkListingLoading(true));
        const resp = await axios.get(`${ApiConfig.adminJobWorkLists}`, {
          params: payload,
        });
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setAllJobWorkListing(resp.data.data));
          dispatch(setAllJobWorkListingLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setAllJobWorkListingError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setAllJobWorkListingLoading(false));
      }
    };
  }
};

export const getSingleJobWorkListing = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setSingleJobWorkListingLoading(true));
        const resp = await axios.get(`${ApiConfig.adminJobWorkLists}`, {
          params: payload,
        });
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setSingleJobWorkListing(resp.data.data));
          dispatch(setSingleJobWorkListingLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setSingleJobWorkListingError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setSingleJobWorkListingLoading(false));
      }
    };
  }
};

export const createJobWorkDetail = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.adminCreateJobWork}`, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp.data.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};

export const updateJobWorkDetail = async (payload, designId) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.put(
        `${ApiConfig.adminUpdateJobWork}/${designId}`,
        payload,
      );
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp?.data?.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
    }
  }
};

export const deleteJobWorkDetail = async (designId) => {
  if (designId !== undefined) {
    try {
      const resp = await axios.delete(
        `${ApiConfig.adminDeleteJobWork}/${designId}`,
      );
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp?.data?.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};
