import React, { useState } from 'react';
import CustomButton from '../../Components/CommonComp/Buttons/CustomButton';
import MainLayout from '../Layouts/MainLayout';
import FabricIssue from './FabricIssue';
import { useNavigate } from 'react-router-dom';

function FabricIssueContainer() {
  const [navParams, setNavParams] = useState({
    navTitle: 'All Issued List Data',
    navSubTitle: 'Welcome to Classy Vouge',
  });
  const navigate = useNavigate();
  const [addUserModal, setAddUserModal] = useState(false);
  const handleAddUserModal = (value = false) => {
    setAddUserModal(value);
  };
  const handleNavParams = (obj) => {
    setNavParams({
      ...navParams,
      ...obj,
    });
  };
  return (
    <MainLayout
      navTitle={navParams.navTitle}
      navSubTitle={navParams.navSubTitle}
      navBarRightComp={
        <CustomButton
          sx={{ padding: '12px 15px', ml: (theme) => theme.spacing(2) }}
          color={'buttonPrimary'}
          onClick={() => navigate('/fabricIssue/issueFabric')}
        >
          Issue New Fabric
        </CustomButton>
      }
    >
      <FabricIssue
        handleNavParams={handleNavParams}
        addUserModal={addUserModal}
        handleAddUserModal={handleAddUserModal}
      />
    </MainLayout>
  );
}

export default FabricIssueContainer;
