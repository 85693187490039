import { Grid } from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import CustomBox from '../CommonComp/CustomBox/CustomBox';
import { DATA_LIMIT } from '../../data/constants';
import * as Yup from 'yup';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import CustomCheckbox from '../CommonComp/CustomInput/CheckboxField';
import Divder from '../CommonComp/Divder';
import Loader from '../CommonComp/CustomLoading/Loader';
import {
  createPartyDetail,
  getSinglePartyListing,
  updatePartyDetail,
} from '../../Services/partyApis';
import { partyItemsSelector } from '../../redux/party';
import { useNavigate } from 'react-router-dom';

const limit = DATA_LIMIT;

function ReceiverPartyDetailPage({ partyId, isEdit }) {
  const { singlePartyList } = useSelector(partyItemsSelector);
  const [isLoadingState, setIsLoadingState] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [mainDataOfProduct, setMainDataOfProfuct] = useState({});
  const handleConfirm = async (values, { resetForm }) => {
    setIsLoadingState(true);
    if (isEdit) {
      let resp = await updatePartyDetail(values, partyId);
      setIsLoadingState(false);
    } else {
      let resp = await createPartyDetail(values);
      if (resp) {
        resetForm({
          name: '',
          organizationName: '',
          isActive: Boolean(true),
          partyType: 'Receiver',
        });
      }
      setIsLoadingState(false);
      navigate('/receiver');
    }
  };
  const getPartyDetails = async () => {
    const payload = {
      page: String(1),
      limit: limit,
      partyId: isEdit ? partyId : '',
    };
    if (isEdit) {
      dispatch(getSinglePartyListing(payload));
    }
  };
  useEffect(() => {
    if (isEdit) {
      getPartyDetails();
    }
  }, []);
  useEffect(() => {
    if (
      singlePartyList?.items &&
      Object.keys(singlePartyList?.items)?.length > 0
    ) {
      setMainDataOfProfuct(singlePartyList?.items);
      // setMainDataOfProfuct({});
    }
  }, [singlePartyList?.items]);
  const formik = useFormik({
    initialValues: {
      name: isEdit ? mainDataOfProduct?.name || '' : '',
      organizationName: isEdit ? mainDataOfProduct?.organizationName || '' : '',
      isActive: isEdit
        ? Boolean(mainDataOfProduct?.isActive) || Boolean(true)
        : Boolean(true),
      partyType: 'Receiver',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Receiver Party Name is required.'),
      organizationName: Yup.string().required('Organization Name is required.'),
      partyType: Yup.string().required('Party Type is required.'),
      isActive: Yup.string().required('Is Active is required.'),
    }),
    enableReinitialize: true,
    onSubmit: handleConfirm,
  });
  return (
    <div>
      {(singlePartyList?.loading || isLoadingState) && <Loader />}
      <form onSubmit={formik.handleSubmit}>
        <CustomBox padding={3}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <CustomInput
                label="Receiver Name"
                fullWidth
                placeholder="Enter Receiver Name"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                }}
                onChange={formik.handleChange}
                name="name"
                value={formik.values.name}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomInput
                label="Organization Name"
                fullWidth
                placeholder="Enter Organization Name"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                }}
                onChange={formik.handleChange}
                name="organizationName"
                value={formik.values.organizationName}
                error={
                  formik.touched.organizationName &&
                  Boolean(formik.errors.organizationName)
                }
                helperText={
                  formik.touched.organizationName &&
                  formik.errors.organizationName
                }
              />
            </Grid>
            <Grid item xs={12}>
              <CustomCheckbox
                label={'Is Active'}
                labelPlacement={'end'}
                subLableText={'Checked'}
                handleChange={(e) => {
                  formik.setFieldValue(
                    'isActive',
                    e.target.checked == true ? true : false,
                  );
                }}
                name="isActive"
                value={Boolean(formik.values.isActive)}
                error={
                  formik.touched.isActive && Boolean(formik.errors.isActive)
                }
                helperText={formik.touched.isActive && formik.errors.isActive}
              />
            </Grid>
          </Grid>
        </CustomBox>
        <Divder spacing={1} />
        <CustomButton
          sx={{ marginTop: 2 }}
          type="submit"
          color={'buttonPrimary'}
        >
          Submit
        </CustomButton>
      </form>
    </div>
  );
}

export default ReceiverPartyDetailPage;
