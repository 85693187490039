import React, { useState } from 'react';
import MainLayout from '../Layouts/MainLayout';
import ReceiverPartyDetails from './ReceiverPartyDetails';

function ReceiverPartyDetailsContainer({ isEdit }) {
  return (
    <MainLayout
      navTitle={'Receiver Party Details'}
      navSubTitle={'Welcome to Classy Vouge'}
    >
      <ReceiverPartyDetails isEdit={isEdit} />
    </MainLayout>
  );
}

export default ReceiverPartyDetailsContainer;
