import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  allJobWorkList: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
  singleJobWorkList: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
};

export const jobWorkSlice = createSlice({
  name: 'jobWork',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setAllJobWorkListing: (state, action) => {
      state.allJobWorkList.items = action.payload;
      state.allJobWorkList.error = {
        isError: false,
        message: '',
      };
    },
    setAllJobWorkListingLoading: (state, action) => {
      state.allJobWorkList.loading = action.payload;
    },
    setAllJobWorkListingError: (state, action) => {
      state.allJobWorkList.items = {};
      state.allJobWorkList.error = action.payload;
    },
    setSingleJobWorkListing: (state, action) => {
      state.singleJobWorkList.items = action.payload;
      state.singleJobWorkList.error = {
        isError: false,
        message: '',
      };
    },
    setSingleJobWorkListingLoading: (state, action) => {
      state.singleJobWorkList.loading = action.payload;
    },
    setSingleJobWorkListingError: (state, action) => {
      state.singleJobWorkList.items = {};
      state.singleJobWorkList.error = action.payload;
    },
  },
});

export default jobWorkSlice.reducer;

// Action creators are generated for each case reducer function
export const {
  setLoading,
  setAllJobWorkListing,
  setAllJobWorkListingLoading,
  setAllJobWorkListingError,
  setSingleJobWorkListing,
  setSingleJobWorkListingLoading,
  setSingleJobWorkListingError,
} = jobWorkSlice.actions;

export const jobWorkItemsSelector = (state) => state.jobWork;
