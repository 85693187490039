import React, { useState } from 'react';
import MainLayout from '../Layouts/MainLayout';
import JobWorkInvoiceDetails from './JobWorkInvoiceDetails';

function JobWorkInvoice({ isEdit }) {
  return (
    <MainLayout
      navTitle={'Biller Invoice'}
      navSubTitle={'Welcome to Classy Vouge'}
    >
      <JobWorkInvoiceDetails isEdit={isEdit} />
    </MainLayout>
  );
}

export default JobWorkInvoice;
