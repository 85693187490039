import React from 'react';
import CustomBox from '../CommonComp/CustomBox/CustomBox';
import AllProduct from './AllBillerParty';
import AllBillerParty from './AllBillerParty';

function BillerPartyTabBody({ activeTab }) {
  return (
    <CustomBox
      padding={0}
      sx={{
        minHeight: 200,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {activeTab == 1 && <AllBillerParty />}
    </CustomBox>
  );
}

export default BillerPartyTabBody;
