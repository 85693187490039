import React, { useState } from 'react';
import MainLayout from '../Layouts/MainLayout';
import FabricIssueDetails from './FabricIssueDetails';

function FabricIssueDetailsContainer({ isEdit }) {
  return (
    <MainLayout
      navTitle={'Fabric Issued Details'}
      navSubTitle={'Welcome to Classy Vouge'}
    >
      <FabricIssueDetails isEdit={isEdit} />
    </MainLayout>
  );
}

export default FabricIssueDetailsContainer;
