import React from 'react';
import CustomBox from '../CommonComp/CustomBox/CustomBox';
import AllDesignList from './AllDesignList';

function DesignListTabBody({ activeTab }) {
  return (
    <CustomBox
      padding={0}
      sx={{
        minHeight: 200,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {activeTab == 1 && <AllDesignList />}
    </CustomBox>
  );
}

export default DesignListTabBody;
