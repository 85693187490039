import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../Context/AuthProvider';
import { LoginUser } from '../../Services/LoginApi';
import { setItemInStorage } from '../../storage/Storage';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import LoginBackgorund from '../../Asset/image/login-back-2.png';
import LoginVectorDown from '../../Asset/image/login_icon1.png';
import LoginVectorUp from '../../Asset/image/login_icon2.png';
import MainLogo from '../../Asset/image/MainLogo.png';
import {
  MailIcon,
  LoginLock,
  LoginEye,
  LoginEyeOff,
  AppLogoNew,
} from '../../Asset/svg';
import CustomButton from '../../Components/CommonComp/Buttons/CustomButton';
import CustomInput from '../../Components/CommonComp/CustomInput/CustomInput';
import { loginItemsSelector, setUserDetails } from '../../redux/login';
import { useDispatch, useSelector } from 'react-redux';

const LoginLayout = () => {
  const { user, login } = useAuth();
  const [isPassVisible, setIsPassVisible] = useState(false);
  // const [loading, setLoading] = useState(false);
  const { loading } = useSelector(loginItemsSelector);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  useEffect(() => {
    if (user) {
      dispatch(setUserDetails(user));
      navigate('/dashboard');
    }
  }, [user]);

  const handleLogin = async (values, { resetForm }) => {
    try {
      const payload = {
        email: values?.email,
        password: values?.password,
      };

      dispatch(LoginUser(payload, login));
    } catch (e) {
      console.log(e, 'error');
    }
  };

  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };
  const handleClickShowPassword = () => {
    setIsPassVisible(!isPassVisible);
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Please enter Email')
        .required('Please enter Email'),
      password: Yup.string().required('Please Enter your password'),
    }),
    onSubmit: handleLogin,
  });
  return (
    <>
      <img
        src={LoginBackgorund}
        alt="login_backgorund"
        className="login-background"
      />
      <div className="main-login-page">
        <div className="login-section">
          <div className="login-div">
            {/* <img src={LoginVectorDown} className="login-vector-down" /> */}
            <form onSubmit={formik.handleSubmit} noValidate>
              <div className="login-logo-section">
                <Link to="/">
                  {/* <img src={MainLogo} className="login-logo-image" /> */}
                  <AppLogoNew
                    style={{
                      maxWidth: 210,
                      maxHeight: 45,
                      width: '100%',
                    }}
                  />
                </Link>
              </div>
              <div className="mainpagetitle title-login">
                Continue with Admin Sign In
              </div>
              <div className="login-main-form">
                <div className="login-form-content">
                  <CustomInput
                    label="Email"
                    className="main-login-form"
                    fullWidth
                    id="email"
                    name="email"
                    placeholder="Email Address"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <MailIcon className="loginform-field-icons" />
                        </InputAdornment>
                      ),
                    }}
                    value={formik.values.email}
                    onChange={(e) => {
                      formik.setFieldValue('email', e.target.value);
                    }}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                </div>
                <div className="login-form-content login-space-divider">
                  <CustomInput
                    label="Password"
                    className="main-login-form"
                    fullWidth
                    id="password"
                    name="password"
                    type={isPassVisible ? 'text' : 'password'}
                    placeholder="Password"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LoginLock className="loginform-field-icons" />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="start">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {isPassVisible ? (
                              <LoginEyeOff className="loginform-field-icons login-eye-icon" />
                            ) : (
                              <LoginEye className="loginform-field-icons login-eye-icon" />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    value={formik.values.password}
                    onChange={(e) => {
                      formik.setFieldValue('password', e.target.value);
                    }}
                    error={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                    helperText={
                      formik.touched.password && formik.errors.password
                    }
                  />
                </div>
              </div>
              {/* <div className="forgot-pass-div-login">
                <button
                  type="button"
                  className="mainsectiontitle forgot-pass-login"
                  onClick={handleClickOpen}
                >
                  Forgot Password?
                </button>
              </div> */}
              <div className="signin-button-main">
                <CustomButton
                  fullWidth
                  sx={{
                    padding: '20px 10px',
                    marginRight: 1,
                    fontSize: '18px',
                    bgcolor: '#000',
                    color: 'buttonSecondary.contrastText',
                    '&:hover': {
                      bgcolor: '#d6d6d6',
                      color: '#000',
                    },
                  }}
                  loading={loading}
                  type="submit"
                >
                  Sign In
                </CustomButton>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginLayout;
