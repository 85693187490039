import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  allDesignList: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
  singleDesignList: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
};

export const designSlice = createSlice({
  name: 'design',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setAllDesignListing: (state, action) => {
      state.allDesignList.items = action.payload;
      state.allDesignList.error = {
        isError: false,
        message: '',
      };
    },
    setAllDesignListingLoading: (state, action) => {
      state.allDesignList.loading = action.payload;
    },
    setAllDesignListingError: (state, action) => {
      state.allDesignList.items = {};
      state.allDesignList.error = action.payload;
    },
    setSingleDesignListing: (state, action) => {
      state.singleDesignList.items = action.payload;
      state.singleDesignList.error = {
        isError: false,
        message: '',
      };
    },
    setSingleDesignListingLoading: (state, action) => {
      state.singleDesignList.loading = action.payload;
    },
    setSingleDesignListingError: (state, action) => {
      state.singleDesignList.items = {};
      state.singleDesignList.error = action.payload;
    },
  },
});

export default designSlice.reducer;

// Action creators are generated for each case reducer function
export const {
  setLoading,
  setAllDesignListing,
  setAllDesignListingLoading,
  setAllDesignListingError,
  setSingleDesignListing,
  setSingleDesignListingLoading,
  setSingleDesignListingError,
} = designSlice.actions;

export const designItemsSelector = (state) => state.design;
