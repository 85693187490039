import React from 'react';
import CustomBox from '../CommonComp/CustomBox/CustomBox';
import AllChalanList from './AllChalanList';
import TopChalanList from './TopChalan';

function ChalanTabBody({ activeTab }) {
  return (
    <CustomBox
      padding={0}
      sx={{
        minHeight: 200,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {activeTab == 1 && <AllChalanList />}
      {activeTab == 2 && <TopChalanList />}
    </CustomBox>
  );
}

export default ChalanTabBody;
