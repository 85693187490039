import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { CustomBrowserRouter } from './Route/CustomBrowserRouter';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <CustomBrowserRouter>
    <App />
  </CustomBrowserRouter>,
);
