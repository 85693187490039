import { Box, Grid, Typography } from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import CustomBox from '../CommonComp/CustomBox/CustomBox';
import { DATA_LIMIT } from '../../data/constants';
import * as Yup from 'yup';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import CustomCheckbox from '../CommonComp/CustomInput/CheckboxField';
import Divder from '../CommonComp/Divder';
import Loader from '../CommonComp/CustomLoading/Loader';
import { getPartyBySearch } from '../../Services/partyApis';
import { partyItemsSelector } from '../../redux/party';
import { useNavigate } from 'react-router-dom';
import MatTable from '../Tables/MatTable';
import {
  createChalanDetail,
  getSingleChalanListing,
  updateChalanDetail,
} from '../../Services/chalanApis';
import { chalanItemsSelector } from '../../redux/chalan';
import CustomAsyncSelect from '../CommonComp/CustomInput/CustomAsyncSelect';
import { getDesignBySearch } from '../../Services/designApis';
import { formatDate } from '../../lib/helper';

const limit = DATA_LIMIT;

function ChalanDetailPage({ billingId, isEdit }) {
  const { singlePartyList } = useSelector(partyItemsSelector);
  const { singleChalanList } = useSelector(chalanItemsSelector);
  const [isLoadingState, setIsLoadingState] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [mainDataOfProduct, setMainDataOfProfuct] = useState({});
  const handleConfirm = async (values, { resetForm }) => {
    setIsLoadingState(true);
    const mainPayload = {
      designList: [],
      billerParty: '',
      receiverParty: '',
      notes: '',
      isActive: true,
      customCreatedAt: values?.customCreatedAt,
    };
    if (values?.billerParty) {
      mainPayload.billerParty = values?.billerParty?.value;
    }
    if (values?.billerParty) {
      mainPayload.receiverParty = values?.receiverParty?.value;
    }
    if (values?.notes) {
      mainPayload.notes = values?.notes;
    }
    if (values?.products && values?.products?.length > 0) {
      const getLatestPayloadForDesignData = await values?.products?.map(
        (data) => {
          let tempCurrentValue = {
            designName: data?.productData?.value,
            designData: [],
          };
          if (data?.xsSize && parseFloat(Number(data?.xsSize)) > 0) {
            tempCurrentValue?.designData?.push({
              product: data?.productData?.value,
              size: 'XS',
              quantity: parseFloat(Number(data?.xsSize)),
            });
          }
          if (data?.sSize && parseFloat(Number(data?.sSize)) > 0) {
            tempCurrentValue?.designData?.push({
              product: data?.productData?.value,
              size: 'S',
              quantity: parseFloat(Number(data?.sSize)),
            });
          }
          if (data?.mSize && parseFloat(Number(data?.mSize)) > 0) {
            tempCurrentValue?.designData?.push({
              product: data?.productData?.value,
              size: 'M',
              quantity: parseFloat(Number(data?.mSize)),
            });
          }
          if (data?.lSize && parseFloat(Number(data?.lSize)) > 0) {
            tempCurrentValue?.designData?.push({
              product: data?.productData?.value,
              size: 'L',
              quantity: parseFloat(Number(data?.lSize)),
            });
          }
          if (data?.xlSize && parseFloat(Number(data?.xlSize)) > 0) {
            tempCurrentValue?.designData?.push({
              product: data?.productData?.value,
              size: 'XL',
              quantity: parseFloat(Number(data?.xlSize)),
            });
          }
          if (data?.xxlSize && parseFloat(Number(data?.xxlSize)) > 0) {
            tempCurrentValue?.designData?.push({
              product: data?.productData?.value,
              size: 'XXL',
              quantity: parseFloat(Number(data?.xxlSize)),
            });
          }
          if (data?.xxxlSize && parseFloat(Number(data?.xxxlSize)) > 0) {
            tempCurrentValue?.designData?.push({
              product: data?.productData?.value,
              size: '3XL',
              quantity: parseFloat(Number(data?.xxxlSize)),
            });
          }
          if (data?.xxxxlSize && parseFloat(Number(data?.xxxxlSize)) > 0) {
            tempCurrentValue?.designData?.push({
              product: data?.productData?.value,
              size: '4XL',
              quantity: parseFloat(Number(data?.xxxxlSize)),
            });
          }
          if (data?.xxxxxlSize && parseFloat(Number(data?.xxxxxlSize)) > 0) {
            tempCurrentValue?.designData?.push({
              product: data?.productData?.value,
              size: '5XL',
              quantity: parseFloat(Number(data?.xxxxxlSize)),
            });
          }
          return tempCurrentValue;
        },
      );
      mainPayload.designList = getLatestPayloadForDesignData;
    }
    if (isEdit) {
      let resp = await updateChalanDetail(mainPayload, billingId);
      setIsLoadingState(false);
    } else {
      let resp = await createChalanDetail(mainPayload);
      if (resp) {
        resetForm({
          sku: '',
          name: '',
          isActive: Boolean(true),
          size: [
            {
              name: 'S',
            },
            {
              name: 'M',
            },
            {
              name: 'L',
            },
            {
              name: 'XL',
            },
            {
              name: 'XXL',
            },
          ],
        });
      }
      setIsLoadingState(false);
      navigate('/chalan');
    }
  };
  const getPartyDetails = async () => {
    const payload = {
      page: String(1),
      limit: limit,
      billingId: isEdit ? billingId : '',
    };
    if (isEdit) {
      dispatch(getSingleChalanListing(payload));
    }
  };
  useEffect(() => {
    if (isEdit) {
      getPartyDetails();
    }
  }, []);
  useEffect(() => {
    if (
      singleChalanList?.items &&
      Object.keys(singleChalanList?.items)?.length > 0
    ) {
      const billerParty = {
        value: singleChalanList?.items?.billerParty?._id,
        label: `${singleChalanList?.items?.billerParty?.name} (${singleChalanList?.items?.billerParty?.organizationName})`,
      };
      const receiverParty = {
        value: singleChalanList?.items?.receiverParty?._id,
        label: `${singleChalanList?.items?.receiverParty?.name} (${singleChalanList?.items?.receiverParty?.organizationName})`,
      };
      const products = singleChalanList?.items?.designList?.map((data) => {
        return {
          productData: {
            value: data?.designName?._id,
            label: `${data?.designName?.sku}`,
            size: data?.designName?.size,
          },
          xsSize:
            data?.designData?.find((data) => data?.size == 'XS')?.quantity || 0,
          sSize:
            data?.designData?.find((data) => data?.size == 'S')?.quantity || 0,
          mSize:
            data?.designData?.find((data) => data?.size == 'M')?.quantity || 0,
          lSize:
            data?.designData?.find((data) => data?.size == 'L')?.quantity || 0,
          xlSize:
            data?.designData?.find((data) => data?.size == 'XL')?.quantity || 0,
          xxlSize:
            data?.designData?.find((data) => data?.size == 'XXL')?.quantity ||
            0,
          xxxlSize:
            data?.designData?.find((data) => data?.size == '3XL')?.quantity ||
            0,
          xxxxlSize:
            data?.designData?.find((data) => data?.size == '4XL')?.quantity ||
            0,
          xxxxxlSize:
            data?.designData?.find((data) => data?.size == '5XL')?.quantity ||
            0,
        };
      });

      setMainDataOfProfuct({
        ...singleChalanList?.items,
        billerParty,
        receiverParty,
        products,
      });
      // setMainDataOfProfuct({});
    }
  }, [singleChalanList?.items]);
  const formik = useFormik({
    initialValues: {
      billerParty: isEdit
        ? mainDataOfProduct?.billerParty || {
            value: '6561744469e2d00008bacb5f',
            label: 'Classy Vouge Stitching',
          }
        : {
            value: '6561744469e2d00008bacb5f',
            label: 'Classy Vouge Stitching',
          },
      notes: isEdit ? mainDataOfProduct?.notes || '' : '',
      receiverParty: isEdit ? mainDataOfProduct?.receiverParty || '' : '',
      isActive: isEdit
        ? Boolean(mainDataOfProduct?.isActive) || Boolean(true)
        : Boolean(true),
      customCreatedAt: isEdit
        ? formatDate(
            new Date(
              mainDataOfProduct?.customCreatedAt ||
                mainDataOfProduct?.createdAt,
            ),
            'yyyy-mm-dd',
          ) || formatDate(new Date(), 'yyyy-mm-dd')
        : formatDate(new Date(), 'yyyy-mm-dd'),
      products: isEdit
        ? mainDataOfProduct?.products || [
            {
              productData: mainDataOfProduct?.products,
              xsSize: 0,
              sSize: 0,
              mSize: 0,
              lSize: 0,
              xlSize: 0,
              xxlSize: 0,
              xxxlSize: 0,
              xxxxlSize: 0,
              xxxxxlSize: 0,
            },
          ]
        : [
            {
              productData: '',
              xsSize: 0,
              sSize: 0,
              mSize: 0,
              lSize: 0,
              xlSize: 0,
              xxlSize: 0,
              xxxlSize: 0,
              xxxxlSize: 0,
              xxxxxlSize: 0,
            },
          ],
    },
    validationSchema: Yup.object({
      isActive: Yup.string().required('Is Active is required.'),
      billerParty: Yup.object().required('Biller Party is required'),
      customCreatedAt: Yup.date().required('Create Date is Required.'),
      receiverParty: Yup.object().required('Receiver Party is required'),
      products: Yup.array().of(
        Yup.object().shape({
          productData: Yup.object().required('product Data is required'),
        }),
      ),
    }),
    enableReinitialize: true,
    onSubmit: handleConfirm,
  });
  const isValidData = () => {
    // if(formik.values?.products)
    return true;
  };
  function isValueInArray(rowIndex, inputValue) {
    if (
      formik?.values?.products &&
      formik?.values?.products[rowIndex].productData
    )
      return formik?.values?.products[rowIndex].productData?.size?.some(
        (item) => item.name == inputValue,
      );
    else {
      return false;
    }
  }

  const columnForPrivacyPolicyData = [
    {
      label: 'Product SKU',
      id: 'productData',
      format: (columnData, rowIndex, formatCallback, arr, errors, row) => (
        <span
          style={{
            display: 'inline-block',
            whiteSpace: 'nowrap',
            width: '100%',
            minWidth: '200px',
          }}
        >
          <CustomAsyncSelect
            promiseOptions={getProductListing}
            // error={error}
            isMulti={false}
            // helperText={helperText}
            closeMenuOnSelect={false}
            controlStyle={{
              padding: 9,
            }}
            cacheOptions={true}
            // value={productsData}
            // handleChange={(e) => {
            //   setProductsData(e);
            // }}
            width={'100%'}
            dropDownZIndex={15000}
            labelShrink={true}
            label="Search"
            id={`products[${rowIndex}].productData`}
            name={`products[${rowIndex}].productData`}
            value={formik.values.products[rowIndex]?.productData}
            onChange={(e) =>
              formik.setFieldValue(`products[${rowIndex}].productData`, e)
            }
            error={
              formik.touched.products &&
              formik.touched.products[rowIndex]?.productData &&
              formik.errors.products &&
              formik.errors.products[rowIndex]?.productData
                ? true
                : false
            }
            helperText={
              formik.touched.products &&
              formik.touched.products[rowIndex]?.productData &&
              formik.errors.products &&
              formik.errors.products[rowIndex]?.productData
            }
          />
        </span>
      ),
    },
    {
      label: 'XS',
      id: 'productData',
      format: (columnData, rowIndex, formatCallback, arr, errors, row) => (
        <span
          style={{
            display: 'inline-block',
            whiteSpace: 'nowrap',
            width: '100%',
          }}
        >
          <CustomInput
            variant="outlined"
            sx={{ minWidth: 100 }}
            label="Value"
            placeholder="Enter Value"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              classes: {
                notchedOutline: 'rounded',
              },
            }}
            disabled={!isValueInArray(rowIndex, 'XS')}
            id={`products[${rowIndex}].xsSize`}
            name={`products[${rowIndex}].xsSize`}
            value={formik.values.products[rowIndex]?.xsSize}
            onChange={(e) =>
              formik.setFieldValue(
                `products[${rowIndex}].xsSize`,
                e.target.value,
              )
            }
            error={
              formik.touched.products &&
              formik.touched.products[rowIndex]?.xsSize &&
              formik.errors.products &&
              formik.errors.products[rowIndex]?.xsSize
                ? true
                : false
            }
            helperText={
              formik.touched.products &&
              formik.touched.products[rowIndex]?.xsSize &&
              formik.errors.products &&
              formik.errors.products[rowIndex]?.xsSize
            }
          />
        </span>
      ),
    },
    {
      label: 'S',
      id: 'productData',
      format: (columnData, rowIndex, formatCallback, arr, errors, row) => (
        <span
          style={{
            display: 'inline-block',
            whiteSpace: 'nowrap',
            width: '100%',
          }}
        >
          <CustomInput
            variant="outlined"
            sx={{ minWidth: 100 }}
            label="Value"
            placeholder="Enter Value"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              classes: {
                notchedOutline: 'rounded',
              },
            }}
            disabled={!isValueInArray(rowIndex, 'S')}
            id={`products[${rowIndex}].sSize`}
            name={`products[${rowIndex}].sSize`}
            value={formik.values.products[rowIndex]?.sSize}
            onChange={(e) => {
              formik.setFieldValue(
                `products[${rowIndex}].sSize`,
                e.target.value,
              );
            }}
            error={
              formik.touched.products &&
              formik.touched.products[rowIndex]?.sSize &&
              formik.errors.products &&
              formik.errors.products[rowIndex]?.sSize
                ? true
                : false
            }
            helperText={
              formik.touched.products &&
              formik.touched.products[rowIndex]?.sSize &&
              formik.errors.products &&
              formik.errors.products[rowIndex]?.sSize
            }
          />
        </span>
      ),
    },
    {
      label: 'M',
      id: 'productData',
      format: (columnData, rowIndex, formatCallback, arr, errors, row) => (
        <span
          style={{
            display: 'inline-block',
            whiteSpace: 'nowrap',
            width: '100%',
          }}
        >
          <CustomInput
            variant="outlined"
            sx={{ minWidth: 100 }}
            label="Value"
            placeholder="Enter Value"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              classes: {
                notchedOutline: 'rounded',
              },
            }}
            disabled={!isValueInArray(rowIndex, 'M')}
            id={`products[${rowIndex}].mSize`}
            name={`products[${rowIndex}].mSize`}
            value={formik.values.products[rowIndex]?.mSize}
            onChange={(e) =>
              formik.setFieldValue(
                `products[${rowIndex}].mSize`,
                e.target.value,
              )
            }
            error={
              formik.touched.products &&
              formik.touched.products[rowIndex]?.mSize &&
              formik.errors.products &&
              formik.errors.products[rowIndex]?.mSize
                ? true
                : false
            }
            helperText={
              formik.touched.products &&
              formik.touched.products[rowIndex]?.mSize &&
              formik.errors.products &&
              formik.errors.products[rowIndex]?.mSize
            }
          />
        </span>
      ),
    },
    {
      label: 'L',
      id: 'productData',
      format: (columnData, rowIndex, formatCallback, arr, errors, row) => (
        <span
          style={{
            display: 'inline-block',
            whiteSpace: 'nowrap',
            width: '100%',
          }}
        >
          <CustomInput
            variant="outlined"
            sx={{ minWidth: 100 }}
            label="Value"
            placeholder="Enter Value"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              classes: {
                notchedOutline: 'rounded',
              },
            }}
            disabled={!isValueInArray(rowIndex, 'L')}
            id={`products[${rowIndex}].lSize`}
            name={`products[${rowIndex}].lSize`}
            value={formik.values.products[rowIndex]?.lSize}
            onChange={(e) =>
              formik.setFieldValue(
                `products[${rowIndex}].lSize`,
                e.target.value,
              )
            }
            error={
              formik.touched.products &&
              formik.touched.products[rowIndex]?.lSize &&
              formik.errors.products &&
              formik.errors.products[rowIndex]?.lSize
                ? true
                : false
            }
            helperText={
              formik.touched.products &&
              formik.touched.products[rowIndex]?.lSize &&
              formik.errors.products &&
              formik.errors.products[rowIndex]?.lSize
            }
          />
        </span>
      ),
    },
    {
      label: 'XL',
      id: 'productData',
      format: (columnData, rowIndex, formatCallback, arr, errors, row) => (
        <span
          style={{
            display: 'inline-block',
            whiteSpace: 'nowrap',
            width: '100%',
          }}
        >
          <CustomInput
            variant="outlined"
            sx={{ minWidth: 100 }}
            label="Value"
            placeholder="Enter Value"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              classes: {
                notchedOutline: 'rounded',
              },
            }}
            disabled={!isValueInArray(rowIndex, 'XL')}
            id={`products[${rowIndex}].xlSize`}
            name={`products[${rowIndex}].xlSize`}
            value={formik.values.products[rowIndex]?.xlSize}
            onChange={(e) =>
              formik.setFieldValue(
                `products[${rowIndex}].xlSize`,
                e.target.value,
              )
            }
            error={
              formik.touched.products &&
              formik.touched.products[rowIndex]?.xlSize &&
              formik.errors.products &&
              formik.errors.products[rowIndex]?.xlSize
                ? true
                : false
            }
            helperText={
              formik.touched.products &&
              formik.touched.products[rowIndex]?.xlSize &&
              formik.errors.products &&
              formik.errors.products[rowIndex]?.xlSize
            }
          />
        </span>
      ),
    },
    {
      label: 'XXL',
      id: 'productData',
      format: (columnData, rowIndex, formatCallback, arr, errors, row) => (
        <span
          style={{
            display: 'inline-block',
            whiteSpace: 'nowrap',
            width: '100%',
          }}
        >
          <CustomInput
            variant="outlined"
            sx={{ minWidth: 100 }}
            label="Value"
            placeholder="Enter Value"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              classes: {
                notchedOutline: 'rounded',
              },
            }}
            disabled={!isValueInArray(rowIndex, 'XXL')}
            id={`products[${rowIndex}].xxlSize`}
            name={`products[${rowIndex}].xxlSize`}
            value={formik.values.products[rowIndex]?.xxlSize}
            onChange={(e) =>
              formik.setFieldValue(
                `products[${rowIndex}].xxlSize`,
                e.target.value,
              )
            }
            error={
              formik.touched.products &&
              formik.touched.products[rowIndex]?.xxlSize &&
              formik.errors.products &&
              formik.errors.products[rowIndex]?.xxlSize
                ? true
                : false
            }
            helperText={
              formik.touched.products &&
              formik.touched.products[rowIndex]?.xxlSize &&
              formik.errors.products &&
              formik.errors.products[rowIndex]?.xxlSize
            }
          />
        </span>
      ),
    },
    {
      label: '3XL',
      id: 'productData',
      format: (columnData, rowIndex, formatCallback, arr, errors, row) => (
        <span
          style={{
            display: 'inline-block',
            whiteSpace: 'nowrap',
            width: '100%',
          }}
        >
          <CustomInput
            variant="outlined"
            sx={{ minWidth: 100 }}
            label="Value"
            placeholder="Enter Value"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              classes: {
                notchedOutline: 'rounded',
              },
            }}
            disabled={!isValueInArray(rowIndex, '3XL')}
            id={`products[${rowIndex}].xxxlSize`}
            name={`products[${rowIndex}].xxxlSize`}
            value={formik.values.products[rowIndex]?.xxxlSize}
            onChange={(e) =>
              formik.setFieldValue(
                `products[${rowIndex}].xxxlSize`,
                e.target.value,
              )
            }
            error={
              formik.touched.products &&
              formik.touched.products[rowIndex]?.xxxlSize &&
              formik.errors.products &&
              formik.errors.products[rowIndex]?.xxxlSize
                ? true
                : false
            }
            helperText={
              formik.touched.products &&
              formik.touched.products[rowIndex]?.xxxlSize &&
              formik.errors.products &&
              formik.errors.products[rowIndex]?.xxxlSize
            }
          />
        </span>
      ),
    },
    {
      label: '4XL',
      id: 'productData',
      format: (columnData, rowIndex, formatCallback, arr, errors, row) => (
        <span
          style={{
            display: 'inline-block',
            whiteSpace: 'nowrap',
            width: '100%',
          }}
        >
          <CustomInput
            variant="outlined"
            sx={{ minWidth: 100 }}
            label="Value"
            placeholder="Enter Value"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              classes: {
                notchedOutline: 'rounded',
              },
            }}
            disabled={!isValueInArray(rowIndex, '4XL')}
            id={`products[${rowIndex}].xxxxlSize`}
            name={`products[${rowIndex}].xxxxlSize`}
            value={formik.values.products[rowIndex]?.xxxxlSize}
            onChange={(e) =>
              formik.setFieldValue(
                `products[${rowIndex}].xxxxlSize`,
                e.target.value,
              )
            }
            error={
              formik.touched.products &&
              formik.touched.products[rowIndex]?.xxxxlSize &&
              formik.errors.products &&
              formik.errors.products[rowIndex]?.xxxxlSize
                ? true
                : false
            }
            helperText={
              formik.touched.products &&
              formik.touched.products[rowIndex]?.xxxxlSize &&
              formik.errors.products &&
              formik.errors.products[rowIndex]?.xxxxlSize
            }
          />
        </span>
      ),
    },
    {
      label: '5XL',
      id: 'productData',
      format: (columnData, rowIndex, formatCallback, arr, errors, row) => (
        <span
          style={{
            display: 'inline-block',
            whiteSpace: 'nowrap',
            width: '100%',
          }}
        >
          <CustomInput
            variant="outlined"
            sx={{ minWidth: 100 }}
            label="Value"
            type="number"
            placeholder="Enter Value"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              classes: {
                notchedOutline: 'rounded',
              },
            }}
            disabled={!isValueInArray(rowIndex, '5XL')}
            id={`products[${rowIndex}].xxxxxlSize`}
            name={`products[${rowIndex}].xxxxxlSize`}
            value={formik.values.products[rowIndex]?.xxxxxlSize}
            onChange={(e) =>
              formik.setFieldValue(
                `products[${rowIndex}].xxxxxlSize`,
                e.target.value,
              )
            }
            error={
              formik.touched.products &&
              formik.touched.products[rowIndex]?.xxxxxlSize &&
              formik.errors.products &&
              formik.errors.products[rowIndex]?.xxxxxlSize
                ? true
                : false
            }
            helperText={
              formik.touched.products &&
              formik.touched.products[rowIndex]?.xxxxxlSize &&
              formik.errors.products &&
              formik.errors.products[rowIndex]?.xxxxxlSize
            }
          />
        </span>
      ),
    },
  ];

  const actionBodyForPrivacyPolicyData = (data, rowIndex) => {
    return (
      <>
        <div style={{ display: 'flex' }}>
          <CustomButton
            onClick={() => handleRemoveNewPrivacyPolicyData(data, rowIndex)}
            sx={{
              padding: '5px 10px',
              marginRight: 1,
              fontSize: '12px',
              bgcolor: 'buttonDanger.main',
              color: 'buttonSecondary.contrastText',
            }}
          >
            Delete
          </CustomButton>
        </div>
      </>
    );
  };

  const handleAddNewPrivacyPolicyData = (value) => {
    let tempArray = [...formik.values?.products];
    let emptyObj = {
      productData: '',
      xsSize: 0,
      sSize: 0,
      mSize: 0,
      lSize: 0,
      xlSize: 0,
      xxlSize: 0,
      xxxlSize: 0,
      xxxxlSize: 0,
      xxxxxlSize: 0,
    };
    tempArray?.push(emptyObj);
    formik.setValues({ ...formik.values, products: tempArray });
  };
  const handleRemoveNewPrivacyPolicyData = (data, rowIndex) => {
    let tempArray = [...formik?.values?.products];
    tempArray.splice(rowIndex, 1);
    formik.setValues({ ...formik?.values, products: tempArray });
  };
  const getProductListing = async (inputValue) => {
    if (inputValue && inputValue.length > 1) {
      const payload = {
        page: String(1),
        limit: 10,
        search: inputValue,
      };
      let data = await getDesignBySearch(payload);
      let updatedData = data?.data?.results?.map((item, index) => {
        return {
          value: item?._id,
          label: `${item?.sku}`,
          ...item,
        };
      });
      return updatedData || [];
    }
  };
  const getBillerPartyListing = async (inputValue) => {
    if (inputValue && inputValue.length > 1) {
      const payload = {
        page: String(1),
        limit: 10,
        search: inputValue,
        partyType: 'Biller',
      };
      let data = await getPartyBySearch(payload);
      let updatedData = data?.data?.results?.map((item, index) => {
        return {
          value: item?._id,
          label: `${item?.name} (${item?.organizationName})`,
        };
      });
      return updatedData || [];
    }
  };
  const getReceiverPartyListing = async (inputValue) => {
    if (inputValue && inputValue.length > 1) {
      const payload = {
        page: String(1),
        limit: 10,
        search: inputValue,
        partyType: 'Receiver',
      };
      let data = await getPartyBySearch(payload);
      let updatedData = data?.data?.results?.map((item, index) => {
        return {
          value: item?._id,
          label: `${item?.name} (${item?.organizationName})`,
        };
      });
      return updatedData || [];
    }
  };
  return (
    <div>
      {(singleChalanList?.loading || isLoadingState) && <Loader />}
      <form onSubmit={formik.handleSubmit}>
        <CustomBox padding={3}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <CustomAsyncSelect
                promiseOptions={getBillerPartyListing}
                isMulti={false}
                closeMenuOnSelect={false}
                controlStyle={{
                  padding: 9,
                }}
                cacheOptions={true}
                width={'100%'}
                dropDownZIndex={15000}
                labelShrink={true}
                label="Biller Party Search"
                defaultValue={[
                  {
                    value: '6546046c55e8814cfcbab9fa',
                    label: 'Keyur Bhai (Classy Vouge Pvt Ltd)',
                  },
                ]}
                id={`billerParty`}
                name={`billerParty`}
                value={formik.values.billerParty}
                onChange={(e) => formik.setFieldValue(`billerParty`, e)}
                error={
                  formik.touched.billerParty &&
                  Boolean(formik.errors.billerParty)
                }
                helperText={
                  formik.touched.billerParty && formik.errors.billerParty
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomAsyncSelect
                promiseOptions={getReceiverPartyListing}
                isMulti={false}
                closeMenuOnSelect={false}
                controlStyle={{
                  padding: 9,
                }}
                cacheOptions={true}
                width={'100%'}
                dropDownZIndex={15000}
                labelShrink={true}
                label="Receiver Party Search"
                id={`receiverParty`}
                name={`receiverParty`}
                value={formik.values.receiverParty}
                onChange={(e) => formik.setFieldValue(`receiverParty`, e)}
                error={
                  formik.touched.receiverParty &&
                  Boolean(formik.errors.receiverParty)
                }
                helperText={
                  formik.touched.receiverParty && formik.errors.receiverParty
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomInput
                label="Notes Extra"
                fullWidth
                placeholder="Notes Extra"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                }}
                onChange={formik.handleChange}
                name="notes"
                value={formik.values.notes}
                error={formik.touched.notes && Boolean(formik.errors.notes)}
                helperText={formik.touched.notes && formik.errors.notes}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomInput
                label="Create Date"
                fullWidth
                type="date"
                placeholder="Enter Create Date"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                }}
                onChange={formik.handleChange}
                name="customCreatedAt"
                value={formik.values.customCreatedAt}
                error={
                  formik.touched.customCreatedAt &&
                  Boolean(formik.errors.customCreatedAt)
                }
                helperText={
                  formik.touched.customCreatedAt &&
                  formik.errors.customCreatedAt
                }
              />
            </Grid>
            <Grid item xs={12}>
              <CustomCheckbox
                label={'Is Active'}
                labelPlacement={'end'}
                subLableText={'Checked'}
                handleChange={(e) => {
                  formik.setFieldValue(
                    'isActive',
                    e.target.checked == true ? true : false,
                  );
                }}
                name="isActive"
                value={Boolean(formik.values.isActive)}
                error={
                  formik.touched.isActive && Boolean(formik.errors.isActive)
                }
                helperText={formik.touched.isActive && formik.errors.isActive}
              />
            </Grid>
          </Grid>
        </CustomBox>
        <Divder spacing={1} />
        <CustomBox padding={0}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              px: (theme) => theme.spacing(3),
              py: (theme) => theme.spacing(2),
            }}
          >
            <Typography variant="h3" sx={{}}>
              Select Product Design & Add Sizes
            </Typography>
            <CustomButton
              className="buttonDense"
              onClick={() => handleAddNewPrivacyPolicyData(true)}
              color={'buttonPrimary'}
            >
              Add New Design
            </CustomButton>
          </Box>
          <Divder spacing={1} />
          <MatTable
            showCheckbox={false}
            columns={columnForPrivacyPolicyData}
            data={formik.values.products || []}
            action={actionBodyForPrivacyPolicyData}
            total={0}
            pagination={false}
          />
        </CustomBox>
        <CustomButton
          sx={{ marginTop: 2 }}
          type="submit"
          color={'buttonPrimary'}
          disabled={!isValidData()}
        >
          Submit
        </CustomButton>
      </form>
    </div>
  );
}

export default ChalanDetailPage;
