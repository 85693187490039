import axios from 'axios';
import { toast } from 'react-toastify';
import ApiConfig from '../config/ApiConfig';
import { errorMessage } from '../lib/helper';
import { STATUS_200, STATUS_300 } from '../data/constants';
import {
  setAllChalanListing,
  setAllChalanListingLoading,
  setAllChalanListingError,
  setSingleChalanListing,
  setSingleChalanListingLoading,
  setSingleChalanListingError,
  setHeroChalanListError,
  setHeroChalanList,
  setHeroChalanListLoading,
} from '../redux/chalan';

export const getAllChalanListing = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setAllChalanListingLoading(true));
        const resp = await axios.get(`${ApiConfig.adminChalanLists}`, {
          params: payload,
        });
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setAllChalanListing(resp.data.data));
          dispatch(setAllChalanListingLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setAllChalanListingError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setAllChalanListingLoading(false));
      }
    };
  }
};

export const getHeroChalanByDate = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setHeroChalanListLoading(true));
        const resp = await axios.get(`${ApiConfig.adminHeroChalanSkuByDate}`, {
          params: payload,
        });
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setHeroChalanList(resp.data.data));
          dispatch(setHeroChalanListLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setHeroChalanListError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setHeroChalanListLoading(false));
      }
    };
  }
};

export const getSingleChalanListing = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setSingleChalanListingLoading(true));
        const resp = await axios.get(`${ApiConfig.adminChalanLists}`, {
          params: payload,
        });
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setSingleChalanListing(resp.data.data));
          dispatch(setSingleChalanListingLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setSingleChalanListingError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setSingleChalanListingLoading(false));
      }
    };
  }
};

export const createChalanDetail = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.adminCreateChalan}`, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp.data.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};

export const updateChalanDetail = async (payload, designId) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.put(
        `${ApiConfig.adminUpdateChalan}/${designId}`,
        payload,
      );
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp?.data?.message, {
          autoClose: 2500,
        });
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
    }
  }
};

export const deleteChalanDetail = async (designId) => {
  if (designId !== undefined) {
    try {
      const resp = await axios.delete(
        `${ApiConfig.adminDeleteChalan}/${designId}`,
      );
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp?.data?.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};
