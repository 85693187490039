export const DesignDataList = {
  tabs: [
    {
      label: 'All Design List',
      id: 1,
    },
  ],
};

export const DefaultDesignSizes = [
  'XS',
  'S',
  'M',
  'L',
  'XL',
  'XXL',
  '3XL',
  '4XL',
  '5XL',
];
