import React, { useState } from 'react';
import MainLayout from '../Layouts/MainLayout';
import ChalanDetails from './JobWorkDetails';
import JobWorkDetails from './JobWorkDetails';

function JobWorkDetailsContainer({ isEdit }) {
  return (
    <MainLayout
      navTitle={'Job Work Details'}
      navSubTitle={'Welcome to Classy Vouge'}
    >
      <JobWorkDetails isEdit={isEdit} />
    </MainLayout>
  );
}

export default JobWorkDetailsContainer;
