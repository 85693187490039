import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import CustomBox from '../CommonComp/CustomBox/CustomBox';
import { DATA_LIMIT } from '../../data/constants';
import * as Yup from 'yup';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import CustomCheckbox from '../CommonComp/CustomInput/CheckboxField';
import Divder from '../CommonComp/Divder';
import Loader from '../CommonComp/CustomLoading/Loader';
import { partyItemsSelector } from '../../redux/party';
import { useNavigate } from 'react-router-dom';
import CustomAsyncSelect from '../CommonComp/CustomInput/CustomAsyncSelect';
import { getDesignBySearch } from '../../Services/designApis';
import { jobWorkItemsSelector } from '../../redux/jobWork';
import {
  createJobWorkDetail,
  getSingleJobWorkListing,
  updateJobWorkDetail,
} from '../../Services/jobWorkApis';

const limit = DATA_LIMIT;

function JobworkDetailPage({ jobWorkId, isEdit }) {
  const { singlePartyList } = useSelector(partyItemsSelector);
  const { singleJobWorkList } = useSelector(jobWorkItemsSelector);
  const [isLoadingState, setIsLoadingState] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [mainDataOfProduct, setMainDataOfProfuct] = useState({});
  const handleConfirm = async (values, { resetForm }) => {
    setIsLoadingState(true);
    const mainPayload = {
      ...values,
      productDesignName: values?.productData?.value,
      isActive: true,
    };
    delete mainPayload.productData;
    if (isEdit) {
      let resp = await updateJobWorkDetail(mainPayload, jobWorkId);
      if (resp) {
        getPartyDetails();
      }
      setIsLoadingState(false);
    } else {
      let resp = await createJobWorkDetail(mainPayload);
      if (resp) {
        resetForm({
          productData: '',
          xsSize: 0,
          sSize: 0,
          mSize: 0,
          lSize: 0,
          xlSize: 0,
          xxlSize: 0,
          xxxlSize: 0,
          xxxxlSize: 0,
          xxxxxlSize: 0,
          totalAvgFabric: 0,
          totalFabricQuantity: 0,
          isActive: Boolean(true),
        });
      }
      setIsLoadingState(false);
      navigate('/jobWork');
    }
  };
  const getPartyDetails = async () => {
    const payload = {
      page: String(1),
      limit: limit,
      jobWorkId: isEdit ? jobWorkId : '',
    };
    if (isEdit) {
      dispatch(getSingleJobWorkListing(payload));
    }
  };
  useEffect(() => {
    if (isEdit) {
      getPartyDetails();
    }
  }, []);
  useEffect(() => {
    if (
      singleJobWorkList?.items &&
      Object.keys(singleJobWorkList?.items)?.length > 0
    ) {
      setMainDataOfProfuct({
        ...singleJobWorkList?.items,
        productData: {
          value: singleJobWorkList?.items?.productDesignName?._id,
          label: `${singleJobWorkList?.items?.productDesignName?.sku}`,
          ...singleJobWorkList?.items?.productDesignName,
          size: singleJobWorkList?.items?.productDesignName?.size,
        },
      });
    }
  }, [singleJobWorkList?.items]);
  const formik = useFormik({
    initialValues: {
      productData: isEdit ? mainDataOfProduct?.productData || '' : '',
      xsSize: isEdit ? mainDataOfProduct?.xsSize || 0 : 0,
      sSize: isEdit ? mainDataOfProduct?.sSize || 0 : 0,
      mSize: isEdit ? mainDataOfProduct?.mSize || 0 : 0,
      lSize: isEdit ? mainDataOfProduct?.lSize || 0 : 0,
      xlSize: isEdit ? mainDataOfProduct?.xlSize || 0 : 0,
      xxlSize: isEdit ? mainDataOfProduct?.xxlSize || 0 : 0,
      xxxlSize: isEdit ? mainDataOfProduct?.xxxlSize || 0 : 0,
      xxxxlSize: isEdit ? mainDataOfProduct?.xxxxlSize || 0 : 0,
      xxxxxlSize: isEdit ? mainDataOfProduct?.xxxxxlSize || 0 : 0,
      totalAvgFabric: isEdit ? mainDataOfProduct?.totalAvgFabric || 0 : 0,
      totalFabricQuantity: isEdit
        ? mainDataOfProduct?.totalFabricQuantity || 0
        : 0,
      isActive: isEdit
        ? Boolean(mainDataOfProduct?.isActive) || Boolean(true)
        : Boolean(true),
    },
    validationSchema: Yup.object({
      productData: Yup.object().required('Product Design is required'),
      isActive: Yup.string().required('Is Active is required.'),
      totalAvgFabric: Yup.number().required('Please Enter Total Avg Fabric'),
      totalFabricQuantity: Yup.number().required(
        'Please Enter Total Fabric Meter',
      ),
    }),
    enableReinitialize: true,
    onSubmit: handleConfirm,
  });
  const isValidData = () => {
    // if(formik.values?.products)
    return true;
  };

  function isValueInArray(inputValue) {
    if (formik?.values?.productData)
      return formik?.values?.productData?.size?.some(
        (item) => item.name == inputValue,
      );
    else {
      return false;
    }
  }

  const columnForPrivacyPolicyData = [
    {
      label: 'XS',
      id: 'productData',
      format: (columnData, rowIndex, formatCallback, arr, errors, row) => (
        <span
          style={{
            display: 'inline-block',
            whiteSpace: 'nowrap',
            width: '100%',
          }}
        >
          <CustomInput
            variant="outlined"
            sx={{ minWidth: 100 }}
            label="Pscs"
            placeholder="Enter Pscs"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              classes: {
                notchedOutline: 'rounded',
              },
            }}
            disabled={!isValueInArray('XS')}
            id={`xsSize`}
            name={`xsSize`}
            value={formik.values.xsSize}
            onChange={(e) => formik.setFieldValue(`xsSize`, e.target.value)}
            error={formik.touched.xsSize && formik.errors.xsSize ? true : false}
            helperText={formik.touched.xsSize && formik.errors.xsSize}
          />
        </span>
      ),
    },
    {
      label: 'S',
      id: 'productData',
      format: (columnData, rowIndex, formatCallback, arr, errors, row) => (
        <span
          style={{
            display: 'inline-block',
            whiteSpace: 'nowrap',
            width: '100%',
          }}
        >
          <CustomInput
            variant="outlined"
            sx={{ minWidth: 100 }}
            label="Pscs"
            placeholder="Enter Pscs"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              classes: {
                notchedOutline: 'rounded',
              },
            }}
            disabled={!isValueInArray('S')}
            id={`sSize`}
            name={`sSize`}
            value={formik.values.sSize}
            onChange={(e) => formik.setFieldValue(`sSize`, e.target.value)}
            error={formik.touched.sSize && formik.errors.sSize ? true : false}
            helperText={formik.touched.sSize && formik.errors.sSize}
          />
        </span>
      ),
    },
    {
      label: 'M',
      id: 'productData',
      format: (columnData, rowIndex, formatCallback, arr, errors, row) => (
        <span
          style={{
            display: 'inline-block',
            whiteSpace: 'nowrap',
            width: '100%',
          }}
        >
          <CustomInput
            variant="outlined"
            sx={{ minWidth: 100 }}
            label="Pscs"
            placeholder="Enter Pscs"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              classes: {
                notchedOutline: 'rounded',
              },
            }}
            disabled={!isValueInArray('M')}
            id={`mSize`}
            name={`mSize`}
            value={formik.values.mSize}
            onChange={(e) => formik.setFieldValue(`mSize`, e.target.value)}
            error={formik.touched.mSize && formik.errors.mSize ? true : false}
            helperText={formik.touched.mSize && formik.errors.mSize}
          />
        </span>
      ),
    },
    {
      label: 'L',
      id: 'productData',
      format: (columnData, rowIndex, formatCallback, arr, errors, row) => (
        <span
          style={{
            display: 'inline-block',
            whiteSpace: 'nowrap',
            width: '100%',
          }}
        >
          <CustomInput
            variant="outlined"
            sx={{ minWidth: 100 }}
            label="Pscs"
            placeholder="Enter Pscs"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              classes: {
                notchedOutline: 'rounded',
              },
            }}
            disabled={!isValueInArray('L')}
            id={`lSize`}
            name={`lSize`}
            value={formik.values.lSize}
            onChange={(e) => formik.setFieldValue(`lSize`, e.target.value)}
            error={formik.touched.lSize && formik.errors.lSize ? true : false}
            helperText={formik.touched.lSize && formik.errors.lSize}
          />
        </span>
      ),
    },
    {
      label: 'XL',
      id: 'productData',
      format: (columnData, rowIndex, formatCallback, arr, errors, row) => (
        <span
          style={{
            display: 'inline-block',
            whiteSpace: 'nowrap',
            width: '100%',
          }}
        >
          <CustomInput
            variant="outlined"
            sx={{ minWidth: 100 }}
            label="Pscs"
            placeholder="Enter Pscs"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              classes: {
                notchedOutline: 'rounded',
              },
            }}
            disabled={!isValueInArray('XL')}
            id={`xlSize`}
            name={`xlSize`}
            value={formik.values.xlSize}
            onChange={(e) => formik.setFieldValue(`xlSize`, e.target.value)}
            error={formik.touched.xlSize && formik.errors.xlSize ? true : false}
            helperText={formik.touched.xlSize && formik.errors.xlSize}
          />
        </span>
      ),
    },
    {
      label: 'XXL',
      id: 'productData',
      format: (columnData, rowIndex, formatCallback, arr, errors, row) => (
        <span
          style={{
            display: 'inline-block',
            whiteSpace: 'nowrap',
            width: '100%',
          }}
        >
          <CustomInput
            variant="outlined"
            sx={{ minWidth: 100 }}
            label="Pscs"
            placeholder="Enter Pscs"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              classes: {
                notchedOutline: 'rounded',
              },
            }}
            disabled={!isValueInArray('XXL')}
            id={`xxlSize`}
            name={`xxlSize`}
            value={formik.values.xxlSize}
            onChange={(e) => formik.setFieldValue(`xxlSize`, e.target.value)}
            error={
              formik.touched.xxlSize && formik.errors.xxlSize ? true : false
            }
            helperText={formik.touched.xxlSize && formik.errors.xxlSize}
          />
        </span>
      ),
    },
    {
      label: '3XL',
      id: 'productData',
      format: (columnData, rowIndex, formatCallback, arr, errors, row) => (
        <span
          style={{
            display: 'inline-block',
            whiteSpace: 'nowrap',
            width: '100%',
          }}
        >
          <CustomInput
            variant="outlined"
            sx={{ minWidth: 100 }}
            label="Pscs"
            placeholder="Enter Pscs"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              classes: {
                notchedOutline: 'rounded',
              },
            }}
            disabled={!isValueInArray('3XL')}
            id={`xxxlSize`}
            name={`xxxlSize`}
            value={formik.values.xxxlSize}
            onChange={(e) => formik.setFieldValue(`xxxlSize`, e.target.value)}
            error={
              formik.touched.xxxlSize && formik.errors.xxxlSize ? true : false
            }
            helperText={formik.touched.xxxlSize && formik.errors.xxxlSize}
          />
        </span>
      ),
    },
    {
      label: '4XL',
      id: 'productData',
      format: (columnData, rowIndex, formatCallback, arr, errors, row) => (
        <span
          style={{
            display: 'inline-block',
            whiteSpace: 'nowrap',
            width: '100%',
          }}
        >
          <CustomInput
            variant="outlined"
            sx={{ minWidth: 100 }}
            label="Pscs"
            placeholder="Enter Pscs"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              classes: {
                notchedOutline: 'rounded',
              },
            }}
            disabled={!isValueInArray('4XL')}
            id={`xxxxlSize`}
            name={`xxxxlSize`}
            value={formik.values.xxxxlSize}
            onChange={(e) => formik.setFieldValue(`xxxxlSize`, e.target.value)}
            error={
              formik.touched.xxxxlSize && formik.errors.xxxxlSize ? true : false
            }
            helperText={formik.touched.xxxxlSize && formik.errors.xxxxlSize}
          />
        </span>
      ),
    },
    {
      label: '5XL',
      id: 'productData',
      format: (columnData, rowIndex, formatCallback, arr, errors, row) => (
        <span
          style={{
            display: 'inline-block',
            whiteSpace: 'nowrap',
            width: '100%',
          }}
        >
          <CustomInput
            variant="outlined"
            sx={{ minWidth: 100 }}
            label="Pscs"
            placeholder="Enter Pscs"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              classes: {
                notchedOutline: 'rounded',
              },
            }}
            disabled={!isValueInArray('5XL')}
            id={`xxxxxlSize`}
            name={`xxxxxlSize`}
            value={formik.values.xxxxxlSize}
            onChange={(e) => formik.setFieldValue(`xxxxxlSize`, e.target.value)}
            error={
              formik.touched.xxxxxlSize && formik.errors.xxxxxlSize
                ? true
                : false
            }
            helperText={
              formik.touched.xxxxxlSize &&
              formik.errors.products &&
              formik.errors.xxxxxlSize
            }
          />
        </span>
      ),
    },
  ];
  const getProductListing = async (inputValue) => {
    if (inputValue && inputValue.length > 1) {
      const payload = {
        page: String(1),
        limit: 10,
        search: inputValue,
      };
      let data = await getDesignBySearch(payload);
      let updatedData = data?.data?.results?.map((item, index) => {
        return {
          value: item?._id,
          label: `${item?.sku}`,
          ...item,
        };
      });
      return updatedData || [];
    }
  };
  return (
    <div>
      {(singleJobWorkList?.loading || isLoadingState) && <Loader />}
      <form onSubmit={formik.handleSubmit}>
        <CustomBox padding={3}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <CustomAsyncSelect
                promiseOptions={getProductListing}
                isMulti={false}
                closeMenuOnSelect={false}
                controlStyle={{
                  padding: 9,
                }}
                cacheOptions={true}
                width={'100%'}
                dropDownZIndex={15000}
                labelShrink={true}
                label="Product SKU"
                id={`productData`}
                name={`productData`}
                value={formik.values.productData}
                onChange={(e) => formik.setFieldValue(`productData`, e)}
                error={
                  formik.touched.productData && formik.errors.productData
                    ? true
                    : false
                }
                helperText={
                  formik.touched.productData && formik.errors.productData
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CustomInput
                label="Total Meter Fabric"
                fullWidth
                placeholder="Total Meter Fabric"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                }}
                onChange={formik.handleChange}
                name="totalFabricQuantity"
                value={formik.values.totalFabricQuantity}
                error={
                  formik.touched.totalFabricQuantity &&
                  Boolean(formik.errors.totalFabricQuantity)
                }
                helperText={
                  formik.touched.totalFabricQuantity &&
                  formik.errors.totalFabricQuantity
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CustomInput
                label="Total Avg Fabric"
                fullWidth
                placeholder="Total Avg Fabric"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                }}
                onChange={formik.handleChange}
                name="totalAvgFabric"
                value={formik.values.totalAvgFabric}
                error={
                  formik.touched.totalAvgFabric &&
                  Boolean(formik.errors.totalAvgFabric)
                }
                helperText={
                  formik.touched.totalAvgFabric && formik.errors.totalAvgFabric
                }
              />
            </Grid>
            <Grid item xs={12}>
              <CustomCheckbox
                label={'Is Active'}
                labelPlacement={'end'}
                subLableText={'Checked'}
                handleChange={(e) => {
                  formik.setFieldValue(
                    'isActive',
                    e.target.checked == true ? true : false,
                  );
                }}
                name="isActive"
                value={Boolean(formik.values.isActive)}
                error={
                  formik.touched.isActive && Boolean(formik.errors.isActive)
                }
                helperText={formik.touched.isActive && formik.errors.isActive}
              />
            </Grid>
          </Grid>
        </CustomBox>
        <Divder spacing={1} />
        <CustomBox padding={0}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              px: (theme) => theme.spacing(3),
              py: (theme) => theme.spacing(2),
            }}
          >
            <Typography variant="h3" sx={{}}>
              Add Psc To Sizes According
            </Typography>
            {/* <CustomButton
              className="buttonDense"
              onClick={() => handleAddNewPrivacyPolicyData(true)}
              color={'buttonPrimary'}
            >
              Add New Design
            </CustomButton> */}
          </Box>
          <Divder spacing={1} />
          <Box sx={{ overflowX: 'auto' }}>
            <Table>
              <TableHead>
                <TableRow>
                  {columnForPrivacyPolicyData &&
                    columnForPrivacyPolicyData.map((data, index) => {
                      return <TableCell key={index}>{data?.label}</TableCell>;
                    })}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  {columnForPrivacyPolicyData &&
                    columnForPrivacyPolicyData.map(
                      ({ id, onClick, format, capitalize }, index) => {
                        return (
                          <TableCell
                            key={`${id}-${index}`}
                            onClick={onClick ? () => onClick(row) : () => false}
                            style={{
                              cursor: onClick ? 'pointer' : 'inherit',
                              textTransform: capitalize ? 'capitalize' : 'none',
                              color: '#000',
                              padding: '13px 8px',
                            }}
                            align="left"
                            sx={{
                              borderBottom: '3px solid #EDEFEF !important',
                            }}
                          >
                            {format()}
                          </TableCell>
                        );
                      },
                    )}
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        </CustomBox>
        <Divder spacing={1} />
        <CustomButton
          sx={{ marginTop: 2 }}
          type="submit"
          color={'buttonPrimary'}
          disabled={!isValidData()}
        >
          Submit
        </CustomButton>
      </form>
    </div>
  );
}

export default JobworkDetailPage;
