import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { usePagination, DOTS } from '../../hooks/usePagination';
import { Grid, InputAdornment, Typography } from '@mui/material';
import './Pgination.css';
// import getDevice from '../../../styles/devices';
import Search from '@mui/icons-material/Search';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import CustomLoading from '../CommonComp/CustomLoading/CustomLoading';

function Pagination(props) {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    className,
    loading
  } = props;

  const [paginationRange, setPaginationrange] = useState([]);
  const { isMobile } = false;
  const pRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  const randomValue = Math.random();

  useEffect(() => {
    setPaginationrange(pRange);
  }, []);

  useEffect(() => {
    setPaginationrange(pRange);
  }, [currentPage, pageSize, totalCount]);

  // If there are less than 2 times in pagination range we shall not render the component
  if (currentPage === 1 && paginationRange && paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange && paginationRange[paginationRange.length - 1];

  return (
    <Grid
      container
      sx={{
        // marginTop: '9px',
        padding: isMobile ? '8px' : '15px 12px 0',
        borderTop: '1px solid #EDEFEF',
      }}
    >
      <Grid
        item
        md={7}
        xs={12}
        style={{ textAlign: 'right', overflow: 'hidden', paddingBottom: '10px' }}
      >
        <ul
          className={classnames('pagination-container', {
            [className]: className,
          })}
        >
          {/* {!isMobile && <li
            className={classnames('pagination-item', {
              disabled: currentPage === 1,
            })}
            onClick={() => onPageChange(1)}
          >
            First
          </li>} */}
          <li
            className={classnames('pagination-item', {
              disabled: loading || currentPage === 1,
            })}
            onClick={onPrevious}
          >
            Prev
          </li>
          {/* {paginationRange &&
            paginationRange.map((pageNumber, index) => { */}
              {/* // If the pageItem is a DOT, render the DOTS unicode character */}
              {/* if (pageNumber === DOTS) { */}
                {/* return (
                  <li key={'page' + index} className="pagination-item dots">
                    &#8230;
                  </li>
                ); */}
                {/* return null
              } */}

              {/* // Render our Page Pills */}
              {/* return (
                <li
                  key={'page' + index}
                  className={classnames('pagination-item', {
                    selected: pageNumber === currentPage,
                  })}
                  onClick={() => onPageChange(pageNumber)}
                >
                  {pageNumber}
                </li>
              );
            })} */}
            {paginationRange.length > 0 && <>  
              <li
                className={classnames('pagination-item', {
                  selected: paginationRange[0] === currentPage,
                })}
                onClick={() => onPageChange(paginationRange[0])}
              >
                {loading ? <CustomLoading loading={loading} loadingStyle={{height: 15, width: 15}}/>  :paginationRange[0]}
              </li>
              
              <li className='pagination-item no-hover-effect' style={{margin: 0}}>
                out of
              </li>
              <li className='pagination-item no-hover-effect' style={{margin: 0}}>
                {lastPage}
              </li>
            </>}
          <li className={classnames('pagination-item', { disabled: loading || currentPage === lastPage, })} onClick={onNext}>
            Next
          </li>
          {/* {!isMobile && <li
            className={classnames('pagination-item', {
              disabled: currentPage === lastPage
            })}
            onClick={() => onPageChange(lastPage)}
          >
            Last
          </li>} */}
        </ul>
      </Grid>
      <Grid
        item
        md={3}
        xs={12}
        sx={{ fontSize: isMobile ? '12px' : '14px', display: 'flex', paddingRight: 2}}
      >
        {/* {!isMobile && "Showing"} {(1 + (pageSize * (currentPage - 1)))} to {lastPage === currentPage ? totalCount : currentPage * pageSize} of <span style={{ fontWeight: 'bold' }}>{totalCount} enteries</span> */}
        <Typography sx={{ fontWeight: '500', alignSelf: 'center', paddingBottom: {md: '0', xs: '14px' }, paddingTop: {md: '0', xs: '14px' }  }}>
          Total Records: {totalCount}
        </Typography>
      </Grid>
      <Grid
        item
        md={2}
        xs={12}
        sx={{ fontSize: isMobile ? '12px' : '14px', display: 'flex' }}
      >
        {/* {!isMobile && "Showing"} {(1 + (pageSize * (currentPage - 1)))} to {lastPage === currentPage ? totalCount : currentPage * pageSize} of <span style={{ fontWeight: 'bold' }}>{totalCount} enteries</span> */}
        <CustomInput
          fullWidth
          placeholder="Search"
          sx={(theme) => ({
            bgcolor: '#F3F3F3',
            borderRadius: theme.shape.borderRadius(4),
            '& fieldset': { border: 'none' },
          })}
          id={`searchPage_${randomValue}`}
          onKeyDown={(e)=>{
            if(e.key == 'Enter'){
              if (Number(e.target.value) > 0) {
                    if (
                      typeof Number(e.target.value) == 'number' &&
                      !Number.isNaN(Number(e.target.value)) &&
                      e.target.value && Number(e.target.value) <= lastPage 
                    ) {
                      onPageChange(Number(e.target.value));
                    }else{
                      e.target.value = ''
                    }
                  }
            }
          }}
          onChange={(e)=>{
            if(e.target.value == "")
              onPageChange(1);
          }}
          // InputLabelProps={{ shrink: false }}
          InputProps={{
            endAdornment: (
              <InputAdornment
                position={'end'}
                style={{cursor: "pointer"}}
                onClick={(e) => {
                  let element = document.getElementById(`searchPage_${randomValue}`);
                  if (element) {
                    if (
                      typeof Number(element.value) == 'number' &&
                      !Number.isNaN(Number(element.value)) &&
                      element.value && Number(element.value) <= lastPage 
                    ) {
                      onPageChange(Number(element.value));
                    }else{
                      element.value = ''
                    }
                  }
                }}
              >
                <Search />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    </Grid>
  );
}

export default Pagination;
