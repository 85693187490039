import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
} from '@mui/material';
import CustomInput from '../../CommonComp/CustomInput/CustomInput';
import CustomButton from '../../CommonComp/Buttons/CustomButton';
import { addFabricToDesign } from '../../../Services/designApis';
import Loader from '../../CommonComp/CustomLoading/Loader';
import { fetchFile, formatDate } from '../../../lib/helper';
import { toast } from 'react-toastify';
import ApiConfig from '../../../config/ApiConfig';
import moment from 'moment';

function GetBillingExcelModal({ open, handleOpen, selectedData }) {
  const [loading, setLoading] = useState(false);
  const handleConfirm = async (values, { resetForm }) => {
    toast.promise(
      fetchFile(
        `${ApiConfig.getAllDataOfChalan}?fromDate=${moment(
          values?.startDate,
        ).toDate()}&toDate=${moment(values?.endDate).toDate()}`,
      ),
      {
        pending: 'Downloading your file....',
        success: 'Downloaded Successfully.',
        error: 'Failed to download file.',
      },
    );
  };
  const formik = useFormik({
    initialValues: {
      startDate: formatDate(new Date(), 'yyyy-mm-dd'),
      endDate: formatDate(new Date(), 'yyyy-mm-dd'),
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      startDate: Yup.date().required('Start Date is Required.'),
      endDate: Yup.date().required('End Date is Required.'),
    }),
    onSubmit: handleConfirm,
  });

  return (
    <div>
      {loading && <Loader />}
      <Dialog maxWidth={'sm'} fullWidth open={open}>
        <DialogTitle variant="h3">{'Choose From & End Date'}</DialogTitle>
        <DialogContent>
          <Box
            noValidate
            component="form"
            // sx={{
            //   width: 400
            // }}
            onSubmit={formik.handleSubmit}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <CustomInput
                  label="From Date"
                  fullWidth
                  placeholder="From Date"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  type="date"
                  onChange={formik.handleChange}
                  name="startDate"
                  value={formik.values.startDate}
                  error={
                    formik.touched.startDate && Boolean(formik.errors.startDate)
                  }
                  helperText={
                    formik.touched.startDate && formik.errors.startDate
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  label="End Date"
                  fullWidth
                  placeholder="End Date"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  type="date"
                  onChange={formik.handleChange}
                  name="endDate"
                  value={formik.values.endDate}
                  error={
                    formik.touched.endDate && Boolean(formik.errors.endDate)
                  }
                  helperText={formik.touched.endDate && formik.errors.endDate}
                />
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </Grid>
            <div
              style={{ display: 'flex', justifyContent: 'end', marginTop: 15 }}
            >
              <CustomButton
                color={'buttonPrimary'}
                onClick={formik.handleSubmit}
                loading={false}
              >
                Confirm
              </CustomButton>
              <CustomButton
                onClick={() => {
                  formik.resetForm({
                    email: '',
                    status: '',
                    nextFollowUpsDate: '',
                    heading: '',
                    description: '',
                    createdBy: '',
                  });
                  handleOpen(false);
                }}
                disabled={loading}
              >
                Cancel
              </CustomButton>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default GetBillingExcelModal;
