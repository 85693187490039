import {
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  InputLabel,
  useTheme,
} from '@mui/material';
import React, { useRef } from 'react';
import AsyncSelect from 'react-select/async';

function CustomAsyncSelect({
  width,
  handleChange,
  promiseOptions,
  error,
  helperText,
  name,
  controlStyle,
  dropDownZIndex,
  label,
  labelShrink,
  ...props
}) {
  const theme = useTheme();
  const asyncRef = useRef(null);
  let errorStatus =
    error?.constructor === Object
      ? name
        ? error[name]
        : null
      : error
      ? error
      : false;
  const blurAsync = () => {
    // asyncRef.current?.blur();
  };
  return (
    <div style={{ width: width || 450 }}>
      <FormControl
        required
        error={errorStatus}
        sx={{
          width: '100%',
        }}
        component="fieldset"
        variant="standard"
      >
        {/* {label && <FormLabel required="false">{label}</FormLabel>} */}
        <InputLabel
          shrink={labelShrink}
          sx={{
            zIndex: 1,
            top: -6,
            left: 10,
            padding: '0 6px',
            background: '#fff',
          }}
          required={props.required}
        >
          {label}
        </InputLabel>
        <AsyncSelect
          isMulti
          cacheOptions
          defaultOptions
          ref={asyncRef}
          loadOptions={promiseOptions}
          onChange={(e) => {
            handleChange(e);
            blurAsync();
          }}
          menuPortalTarget={document.body}
          styles={{
            menuPortal: (base) => ({
              ...base,
              zIndex: dropDownZIndex,
              marginBottom: '2rem',
            }),
            control: (baseStyles, state) => ({
              ...baseStyles,
              borderColor: errorStatus
                ? '#d32f2f'
                : state.isFocused
                ? theme.palette.appColor.contrastText
                : theme.palette.appColor.contrastText,
              borderWidth: state.isFocused ? '2px' : '1px',
              boxShadow: '0 0 0 0',
              borderRadius: theme.shape.borderRadius(8),
              padding: 1.5,
              ...controlStyle,
            }),
          }}
          maxMenuHeight={200}
          {...props}
        />
        {error?.constructor === Object ? (
          helperText && error[name] ? (
            <FormHelperText>{helperText}</FormHelperText>
          ) : null
        ) : helperText && error ? (
          <FormHelperText>{helperText}</FormHelperText>
        ) : null}
      </FormControl>
    </div>
  );
}

export default React.memo(CustomAsyncSelect);

CustomAsyncSelect.defaultProps = {
  promiseOptions: () => [],
  width: 450,
  dropDownZIndex: 100,
  handleChange: () => {},
  label: '',
  labelShrink: false,
  required: false,
};
